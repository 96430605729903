import React, { useEffect, useState } from "react";
import searchIcon from "../../assets/icons-search-24-px.svg";
import NavBar from "../../components/jobSeekers/NavBar";
import { useNavigate } from "react-router-dom";
import CardBusiness from "../../components/jobSeekers/CardBusiness";

const categories = [
  "Manicure and Pedicure",
  "Tattoos",
  "Spas",
  "Waxing Services",
];

const ratings = ["2 stars and below", "3 stars", "4 stars and above"];

const experiences = ["Beginner", "Intermediate", "Expert"];

const Home = () => {
  const navigate = useNavigate();
  const [businessOwners, setBusinessOwners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({
    county: "",
    rating: "",
    sub_county: "",
    services_business: "",
    page: 1,
  });
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [showExperienceModal, setShowExperienceModal] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedRatings, setSelectedRatings] = useState([]);
  const [selectedExperiences, setSelectedExperiences] = useState([]);

  const handleBusinessCardClick = (user) => {
    navigate(`/BusinessOverviewPage/${user.id}`);
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  const handleSearchClick = () => {
    fetchData();
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const queryString = new URLSearchParams(searchParams).toString();
      const response = await fetch(
        `https://api.globalbiva.co.ke/api/business-owners/?${queryString}`,
        requestOptions
      );
      const businessesData = await response.json();
      setBusinessOwners(businessesData.results || []);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCategoryClick = () => {
    setShowCategoryModal(!showCategoryModal);
  };

  const handleRatingClick = () => {
    setShowRatingModal(!showRatingModal);
  };

  const handleExperienceClick = () => {
    setShowExperienceModal(!showExperienceModal);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((cat) => cat !== category)
        : [...prevCategories, category]
    );
  };

  const handleRatingSelect = (rating) => {
    setSelectedRatings((prevRatings) =>
      prevRatings.includes(rating)
        ? prevRatings.filter((rat) => rat !== rating)
        : [...prevRatings, rating]
    );
  };

  const handleExperienceSelect = (experience) => {
    setSelectedExperiences((prevExperiences) =>
      prevExperiences.includes(experience)
        ? prevExperiences.filter((exp) => exp !== experience)
        : [...prevExperiences, experience]
    );
  };

  const closeModal = () => {
    setShowCategoryModal(false);
    setShowRatingModal(false);
    setShowExperienceModal(false);
  };

  return (
    <div className="bg-black text-white min-h-screen flex flex-col items-center">
      <NavBar />
      <main className="w-full flex items-center flex-col max-w-screen-lg px-4 sm:px-6 lg:px-8 mt-8">
        <div className="bg-[#322824] flex flex-wrap justify-between items-center gap-4 mb-8 rounded-md w-fit px-6">
          <div className="flex flex-wrap justify-center items-center gap-4 w-full md:w-auto">
            <div className="bg-[#322824] p-4 flex flex-col items-left border-r border-[#dcc6c2] w-full md:w-auto">
              <label className="text-[#dcc6c2] text-xs mb-1">Where?</label>
              <input
                type="text"
                name="county"
                className="text-[#dcc6c2] bg-[#322824] text-sm appearance-none w-full md:w-40 p-2 rounded-md"
                placeholder="Enter location"
                value={searchParams.county}
                onChange={handleSearchChange}
              />
            </div>
            <div
              className="bg-[#322824] p-4 flex flex-col items-left border-r border-[#dcc6c2] w-full md:w-auto cursor-pointer"
              onClick={handleCategoryClick}
            >
              <span className="text-[#dcc6c2] text-xs mb-1">Category</span>
              <span className="text-[#dcc6c2] text-sm mt-1">
                {selectedCategories.join(", ") || "Select Category"}
              </span>
            </div>
            <div
              className="bg-[#322824] p-4 flex flex-col items-left border-r border-[#dcc6c2] w-full md:w-auto cursor-pointer"
              onClick={handleExperienceClick}
            >
              <span className="text-[#dcc6c2] text-xs mb-1">Experience</span>
              <span className="text-[#dcc6c2] text-sm mt-1">
                {selectedExperiences.join(", ") || "Select Experience"}
              </span>
            </div>
            <div
              className="bg-[#322824] p-4 flex flex-col items-left w-full md:w-auto cursor-pointer"
              onClick={handleRatingClick}
            >
              <span className="text-[#dcc6c2] text-xs mb-1">Ratings</span>
              <span className="text-[#dcc6c2] text-sm mt-1">
                {selectedRatings.join(", ") || "Select Ratings"}
              </span>
            </div>
          </div>
          <div
            className="bg-[#322824] p-4 rounded-lg flex items-center cursor-pointer ml-4"
            onClick={handleSearchClick}
          >
            <img src={searchIcon} alt="search" className="h-6 w-6" />
          </div>
        </div>

        {showCategoryModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
            <div className="bg-[#1a120e] p-8 rounded-lg">
              <h2 className="text-[#dcc6c2] text-lg mb-4">Service Category</h2>
              <div className="flex flex-wrap gap-2">
                {categories.map((category) => (
                  <button
                    key={category}
                    className={`p-2 rounded-md border ${
                      selectedCategories.includes(category)
                        ? "bg-[#dcc6c2] text-[#322824]"
                        : "bg-[#322824] text-[#dcc6c2] border-[#dcc6c2]"
                    }`}
                    onClick={() => handleCategorySelect(category)}
                  >
                    {category}
                  </button>
                ))}
              </div>
              <div className="mt-4 flex justify-end">
                <button
                  className="bg-[#dcc6c2] text-[#322824] p-2 rounded-md"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        {showRatingModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
            <div className="bg-[#1a120e] p-8 rounded-lg">
              <h2 className="text-[#dcc6c2] text-lg mb-4">Ratings</h2>
              <div className="flex flex-wrap gap-2">
                {ratings.map((rating) => (
                  <button
                    key={rating}
                    className={`p-2 rounded-md border ${
                      selectedRatings.includes(rating)
                        ? "bg-[#dcc6c2] text-[#322824]"
                        : "bg-[#322824] text-[#dcc6c2] border-[#dcc6c2]"
                    }`}
                    onClick={() => handleRatingSelect(rating)}
                  >
                    {rating}
                  </button>
                ))}
              </div>
              <div className="mt-4 flex justify-end">
                <button
                  className="bg-[#dcc6c2] text-[#322824] p-2 rounded-md"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        {showExperienceModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
            <div className="bg-[#1a120e] p-8 rounded-lg">
              <h2 className="text-[#dcc6c2] text-lg mb-4">
                Level of Experience
              </h2>
              <div className="flex flex-wrap gap-2">
                {experiences.map((experience) => (
                  <button
                    key={experience}
                    className={`p-2 rounded-md border ${
                      selectedExperiences.includes(experience)
                        ? "bg-[#dcc6c2] text-[#322824]"
                        : "bg-[#322824] text-[#dcc6c2] border-[#dcc6c2]"
                    }`}
                    onClick={() => handleExperienceSelect(experience)}
                  >
                    {experience}
                  </button>
                ))}
              </div>
              <div className="mt-4 flex justify-end">
                <button
                  className="bg-[#dcc6c2] text-[#322824] p-2 rounded-md"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 w-full">
              {businessOwners.length > 0 ? (
                businessOwners.map((business, index) => (
                  <CardBusiness
                    key={index}
                    user={business}
                    handleCardClick={handleBusinessCardClick}
                  />
                ))
              ) : (
                <p>No businesses found.</p>
              )}
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default Home;
