import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const EmployeeCard = ({ employeeId }) => {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState(null);
  const [services, setServices] = useState([]);


  const handleLeaveAReview = () => {
    navigate(`/leave-a-review/${employeeId}`);
  };

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        const response = await fetch(
          `https://api.globalbiva.co.ke/api/beauty-professionals/${employeeId}`,
          {
            method: "GET",
            redirect: "follow",
          }
        );
        const data = await response.json();
        console.log(data, "Employee data");
        setEmployee(data);

        if (data.services && data.services.length > 0) {
          const serviceDetails = await fetchServiceDetails(data.services);
          setServices(serviceDetails);
        }
      } catch (error) {
        console.error("An error occurred fetching employee data:", error);
      }
    };

    const fetchServiceDetails = async (serviceIds) => {
      try {
        const serviceRequests = serviceIds.map((id) =>
          fetch(`https://api.globalbiva.co.ke/api/services/${id}`, {
            method: "GET",
            redirect: "follow",
          }).then((response) => response.json())
        );

        const serviceDetails = await Promise.all(serviceRequests);
        return serviceDetails;
      } catch (error) {
        console.error("An error occurred fetching service data:", error);
        return [];
      }
    };

    fetchEmployeeDetails();
  }, [employeeId]);

  if (!employee) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-[#1a120e] w-full p-2 rounded-lg border border-[#52443d] flex flex-row md:flex-col mb-2 md:mb-0">
      <div className="w-full flex flex-col md:flex-row mb-2 md:mb-0">
        <div className="flex-shrink-0 w-1/3 mr-2 md:w-1/4">
          <img
            src={employee.profile_pic}
            alt={employee.name}
            className="h-32 w-24 rounded-lg mb-2 md:mb-0 md:mr-2 object-cover"
          />
        </div>
        <div className="flex-1">
          <div className="flex justify-between items-center mb-1">
            <h1 className="text-[#d7c2b9] text-lg font-normal">
              {employee.name}
            </h1>
          </div>
          <div className="text-[#d7c2b9] text-xs">
            <p className="mb-1 flex justify-between text-base font-light">
              <span>{employee.more.sub_county}</span>
              <span>{employee.joined_date}</span>
            </p>
            <p className="mb-1 flex justify-between text-base font-light">
              <span>{employee.more.experience_level}</span>
            </p>
            <p className="mb-1 flex justify-between text-base font-light">
              <span>Joined Company</span>
              <span>2024/07/25</span>
            </p>

            <div className="mt-2">
              <div className="flex flex-wrap gap-2">
                {services.map((service, index) => (
                  <span
                    key={index}
                    className="text-[#dcc6c2] px-3 py-1 rounded-md"
                  >
                    {service.name}
                  </span>
                ))}
              </div>
            </div>

            <button className="bg-red text-white px-4 py-2 rounded-md mt-2" onClick={handleLeaveAReview}>
              Leave a Review
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeCard;
