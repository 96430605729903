import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/customers/Home";
import ProfessionalOverview from "../pages/customers/ProfessionalOverview";
import Appointments from "../pages/customers/Appointments";
import ProfileSettings from "../pages/customers/ProfileSettings";
import ResetPassword from "../pages/customers/ResetPassword";
import BusinessOverview from "../pages/customers/BusinessOverview";
import OTPVerification from "../pages/customers/OTPVerification";
import NewPasswordSet from "../pages/customers/NewPasswordSet";
import ProfessionalBooking from "../pages/customers/ProfessionalBooking";
import BusinessBooking from "../pages/customers/BusinessBooking";
import ProfileDetails from "../pages/customers/ProfileDetails";
import Summary from "../pages/customers/Summary";


const CustomerRoutes = () => (
  <Routes>
    <Route path="/customer-home" element={<Home />} />
    <Route path="/ProfessionalOverview/:id" element={<ProfessionalOverview />} />
    <Route path="/BusinessOverview/:id" element={<BusinessOverview />} />
    <Route path="/professional-booking/:id" element={<ProfessionalBooking />} />
    <Route path="/business-booking/:id" element={<BusinessBooking />} />
    <Route path="/appointments" element={<Appointments />} />
    <Route path="/customer-profile" element={<ProfileSettings />} />
    <Route path='/appointments-summary' element={<Summary />} />
    <Route path="/customer-settings" element={<ProfileDetails />} />
    <Route path="/reset-password" element={<ResetPassword />} />
    <Route path="/otp-verification" element={<OTPVerification />} />
    <Route path="/set-new-password" element={<NewPasswordSet />} />
  </Routes>
);

export default CustomerRoutes;
