import React, { useEffect, useState } from "react";
import { IoIosArrowBack, IoMdAdd } from "react-icons/io";
import { FaStar, FaRegEdit, FaShieldAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import NavBar from "../../components/jobSeekers/NavBar";
import { BiPencil } from "react-icons/bi";
import { GiComb } from "react-icons/gi";

const ProfileComponent = () => {
  const [user, setUser] = useState({});
  const [services, setServices] = useState([
    { name: "Manicure", icon: <FaShieldAlt /> },
    { name: "Pedicure", icon: <FaShieldAlt /> },
    { name: "Massage", icon: <FaShieldAlt /> },
  ]);

  const fetchUser = async () => {
    const token = Cookies.get("accessToken");

    if (token) {
      const decoded = jwtDecode(token);
      const userId = decoded.id;

      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://api.globalbiva.co.ke/api/beauty-professionals/${userId}/`,
        requestOptions
      );

      const data = await response.json();
      setUser(data);
    } else {
      alert("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <div className="min-h-screen bg-black text-white flex flex-col items-center">
      <NavBar />
      <div className="w-full max-w-screen-lg flex flex-col items-start py-12 px-4 sm:px-6 lg:px-8">
        <button onClick={() => window.history.back()} className="mb-4">
          <IoIosArrowBack className="text-white h-6 w-6" />
        </button>

        {user && (
          <>
            <div className="flex flex-col lg:flex-row w-full mb-8 space-y-4 lg:space-y-0 lg:space-x-4 items-start lg:items-center">
              <div className="flex w-3/4 space-x-2 bg-yellow-950 items-center border rounded-sm border-[#ffffff] pb-4 lg:pb-0 lg:border-none">
                <div className="relative ">
                  <img
                    // src={user.profile_pic || "https://via.placeholder.com/150"}
                    src={"https://via.placeholder.com/150"}
                    alt="Profile"
                    className="h-30 w-30 left-0 ml-0"
                  />
                  <button className="absolute bottom-0 right-0 bg-gray-800 p-1 rounded-full">
                    <FaRegEdit className="text-white h-4 w-4" />
                  </button>
                </div>
                <div className="w-full flex justify-left space-x-4 items-center ">
                  <div className="ml-4  text-gray-500">
                    <p className="text-lg font-medium">{user.name}</p>
                    <p className="text-sm">
                      {user.location || "Embakasi, Nairobi"}
                    </p>
                  </div>
                  <BiPencil className="text-red" />
                </div>
              </div>

              <div className="flex w-3/4 py-2 space-x-2 bg-yellow-950 items-center border rounded-sm border-[#ffffff] pb-4 lg:pb-0 lg:border-none">
                <div className="flex  w-full  flex-col items-center border-b border-[#52443d] pb-4 lg:pb-0 lg:border-none">
                  <div className="flex text-sm  font-normal flex-col items-center space-x-2">
                    <span>0</span>
                    <div className="flex space-x-1">
                      <FaStar className="text-white h-5 w-5" />
                      <FaStar className="text-white h-5 w-5" />{" "}
                      <FaStar className="text-white h-5 w-5" />{" "}
                      <FaStar className="text-white h-5 w-5" />{" "}
                      <FaStar className="text-white h-5 w-5" />
                    </div>
                  </div>
                </div>

                <div className="flex  w-full flex-col items-center border-b border-[#52443d] pb-4 lg:pb-0 lg:border-none">
                  <GiComb className="h-10 w-10" />
                  <h3>Expert</h3>
                </div>
                <div className="flex  w-full flex-col items-center border-b border-[#52443d] pb-4 lg:pb-0 lg:border-none">
                  <p className="text-sm flex flex-col justify-center items-center">
                    {" "}
                    <span>0</span>
                    
                    <span>
                    
                    Reviews
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full mb-8">
              <h2 className="text-lg font-semibold">Our Services</h2>
              <div className="flex flex-wrap items-center mt-4 space-x-4">
                {services.map((service, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center p-4 border-b border-[#52443d]"
                  >
                    {service.icon}
                    <p className="text-sm mt-2">{service.name}</p>
                  </div>
                ))}
                <button className="flex flex-col items-center p-4 border-b border-[#52443d]">
                  <IoMdAdd className="text-white h-6 w-6" />
                  <p className="text-sm mt-2">Add service</p>
                </button>
              </div>
            </div>

            <div className="w-full">
              <p className="text-center">
                When your customers or employees write you a review, it will
                appear here
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileComponent;
