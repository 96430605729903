import React, { useEffect, useState } from "react";
import starIcon from "../../assets/icons-star.svg";
import circleIcon from "../../assets/circle-icon.png";

const CardBusiness = ({ user, handleCardClick }) => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const requestOptions = {
          method: "GET",
          redirect: "follow",
        };

        const response = await fetch(
          "https://api.globalbiva.co.ke/api/services/",
          requestOptions
        );
        const data = await response.json();
        setServices(data.results);
      } catch (error) {
        console.log(error);
      }
    };
    fetchServices();
  }, []);

  const getServiceNameById = (serviceId) => {
    const service = services.find((s) => s.id === serviceId);
    return service ? service.name : "Unknown Service";
  };

  const formatRating = (rating) => {
    return Number.isInteger(rating) ? rating : rating.toFixed(1);
  };

  return (
    <div
      className="bg-[#1a120e] w-full p-2 rounded-lg border border-[#52443d] flex flex-col md:flex-row mb-2 md:mb-0"
      onClick={() => handleCardClick(user)}
    >
      <div className="flex-shrink-0 w-1/3 md:w-1/4">
        <img
          src={user.profile_pic}
          alt={user.more.business_name}
          className="h-32 w-24 rounded-lg mb-2 md:mb-0 md:mr-2 object-cover"
        />
      </div>
      <div className="flex-1">
        <div className="flex justify-between items-center mb-1">
          <span className="text-[#d7c2b9] text-base font-semibold">
            {user.more.business_name}
          </span>
          <div className="flex items-center">
            <img src={starIcon} alt="star" className="h-3 w-3 mr-1" />
            <span className="text-[#d7c2b9] text-xs">
              {formatRating(user.more.rating)}
            </span>
          </div>
        </div>
        <div className="text-[#d7c2b9] text-xs">
          {user.more.availability && (
            <p className="mb-2 flex justify-between">
              <span className="font-semibold">Availability:</span>
              <span className="ml-1">{user.more.availability}</span>
            </p>
          )}
          <p className="mb-1 flex justify-between">
            <span>{user.more.business_location}</span>
            {/* <span>{user.more.ra}</span> */}
          </p>
          <div className="flex flex-wrap m-2">
            {user?.services_business.map((serviceId, index) => (
              <span
                key={index}
                className="text-[#d7c2b9] flex items-center text-xs border border-transparent pr-1 mr-4 mb-2"
              >
                <img src={circleIcon} alt="circle" className="h-2 w-2 mr-1" />
                {getServiceNameById(serviceId)}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardBusiness;
