import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputField from "../../components/shared/InputField";
import SelectButton from "../../components/shared/SelectButton";
import Button from "../../components/shared/Button";
import googleIcon from "../../assets/flat-color-icons_google.svg";
import AuthenticationHeader from "../../components/shared/AuthenticationHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignUpPage = () => {
  const [formData, setFormData] = useState({
    user_type: "",
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    isTermsChecked: false,
  });
  const [avatarUrl, setAvatarUrl] = useState("");
  const navigate = useNavigate();

  const apiEndpoints = {
    "Business Owner": "https://api.globalbiva.co.ke/api/business-owners/",
    "Retail Customer": "https://api.globalbiva.co.ke/api/retail-customers/",
    "Beauty Professional":
      "https://api.globalbiva.co.ke/api/beauty-professionals/",
  };

  useEffect(() => {
    if (formData.navigateTo) {
      navigate(formData.navigateTo);
    }
  }, [formData.navigateTo, navigate]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const generateAvatar = async (name) => {
    const response = await fetch(`https://api.multiavatar.com/${name}.png`);
    const blob = await response.blob();
    const file = new File([blob], `${name}.png`, { type: blob.type });

    const url = URL.createObjectURL(blob);
    setAvatarUrl(url);

    return file;
  };

  const validateForm = () => {

    console.log(formData);
    const {
      name,
      email,
      phone,
      password,
      confirmPassword,
      user_type,
      isTermsChecked,
    } = formData;
    if (
      !name ||
      !email ||
      !phone ||
      !password ||
      !confirmPassword ||
      !user_type ||
      !isTermsChecked
    ) {
      toast.error("Please fill out all fields and agree to the terms.");
      return false;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return false;
    }
    return true;
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const avatarFile = await generateAvatar(formData.name);
      const signupData = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        if (key !== "confirmPassword" && key !== "isTermsChecked") {
          signupData.append(key, value);
        }
      });
      signupData.append("avatar", avatarFile.name);

      if (formData.user_type === "Business Owner") {
        [
          "business_name",
          "county",
          "sub_county",
          "business_location",
          "business_bio",
        ].forEach((key) => signupData.append(`more.${key}`, ""));
      } else if (formData.user_type === "Beauty Professional") {
        [
          "experience_level",
          "availability",
          "employment_status",
          "bio",
          "county",
          "sub_county",
          "current_employment",
          "target_employment",
          "working_hours_start",
          "working_hours_end",
          "services[]",
          "service_category[]",
        ].forEach((key) => signupData.append(`more.${key}`, ""));
      }

      const response = await fetch(apiEndpoints[formData.user_type], {
        method: "POST",
        body: signupData,
        redirect: "follow",
      });
      const result = await response.json();

      console.log(result);

      const user_type = result.user_type; 
      console.log(user_type);
      if (response.ok) {
        toast.success("Sign up successful");
        switch (user_type) {
          case "Business Owner":
            navigate(`/finish-setup-business/${result.id}`);
            break;
          case "Retail Customer":
            navigate("/sign-in");
            break;
          case "Beauty Professional":
            navigate(`/finish-setup-business-professional/${result.id}`);
            break;
          default:
            console.error("Unknown user type selected");
            break;
        }
      } else {
        toast.error(`Sign up failed`);
      }
    } catch (error) {
      console.error("Sign up failed", error);
      toast.error("Sign up failed. Please try again later.");
    }
  };

  const handleGoogleSignUp = () => { }

  return (
    <>
      <ToastContainer />
      <div className="bg-black text-white min-h-screen flex flex-col items-center justify-center">
        <AuthenticationHeader />
        <main className="max-w-screen-lg w-3/4 flex flex-col items-start py-12 px-4 sm:px-6 lg:px-8">
          <h1 className="text-xl mb-8 text-left">
            Register an account with us
          </h1>
          <div className="bg-[#1a120e] p-8 rounded-md w-full">
            <h2 className="text-center mb-4 text-lg">
              Which of the following describes you?
            </h2>
            <div className="flex items-center flex-col">
              <SelectButton
                texts={[
                  "Business Owner",
                  "Retail Customer",
                  "Beauty Professional",
                ]}
                selected={formData.user_type}
                setSelected={(value) =>
                  handleInputChange({ target: { name: "user_type", value } })
                }
              />
            </div>

            <form
              className="mt-8 space-y-6 flex flex-col items-center"
              onSubmit={handleSignUp}
            >
              <div className="flex flex-col md:flex-row md:space-x-4 w-3/4">
                {["name", "email", "phone"].map((field) => (
                  <InputField
                    key={field}
                    label={field.charAt(0).toUpperCase() + field.slice(1)}
                    type={field === "email" ? "email" : "text"}
                    name={field}
                    value={formData[field]}
                    onChange={handleInputChange}
                    required
                  />
                ))}
              </div>
              <div className="flex flex-col md:flex-row md:space-x-4 w-3/4">
                {["password", "confirmPassword"].map((field) => (
                  <InputField
                    key={field}
                    label={
                     field.charAt(0).toUpperCase() + field.slice(1) 
                    }
                    type="password"
                    name={field}
                    value={formData[field]}
                    autoComplete="new-password"
                    onChange={handleInputChange}
                    required
                  />
                ))}
              </div>
              <div className="flex items-center justify-center mt-6">
                <input
                  type="checkbox"
                  name="isTermsChecked"
                  id="terms"
                  className="mr-2"
                  checked={formData.isTermsChecked}
                  onChange={handleInputChange}
                  required
                />
                <label htmlFor="terms" className="text-sm">
                  By signing in to this app you agree to <br />
                  the <span className="text-red">Terms of Service</span> and
                  acknowledge <br />
                  the <span className="text-red">Privacy Policy</span>
                </label>
              </div>
              <Button
                type="submit"
                className="bg-red text-white rounded-md px-2 py-1 md:px-4 md:py-2 w-24 md:w-36"
                text="Register"
              />
            </form>
            <p className="text-center mt-4">
              Already have an account?{" "}
              <Link className="text-red" to="/sign-in">
                Login
              </Link>
            </p>
            <div className="flex items-center my-6">
              <hr className="flex-1 border-gray-600" />
              <span className="px-4 text-white">Or Continue with</span>
              <hr className="flex-1 border-gray-600" />
            </div>
            <div className="flex justify-center space-x-4">
              <button onClick={() => handleGoogleSignUp()}>
                <img
                  src={googleIcon}
                  width="40"
                  height="40"
                  alt="Google"
                  className="bg-[#52443d] p-2 rounded-md"
                />
              </button>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default SignUpPage;
