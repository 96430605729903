import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputField from "../../components/shared/InputField";
import SelectButton from "../../components/shared/SelectButton";
import Button from "../../components/shared/Button";
import googleIcon from "../../assets/flat-color-icons_google.svg";
import appleIcon from "../../assets/cib_apple.svg";
import twitterIcon from "../../assets/twitter.svg";
import AuthenticationHeader from "../../components/shared/AuthenticationHeader";

const SignUpPage = () => {
  const [selected, setSelected] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [navigateTo, setNavigateTo] = useState(null);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (navigateTo) {
      navigate(navigateTo);
    }
  }, [navigateTo, navigate]);

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (email && phone && password && confirmPassword && isTermsChecked) {
      if (password !== confirmPassword) {
        console.error("Passwords do not match");
        return;
      }

      try {
        const formdata = new FormData();
        formdata.append("email", email);
        formdata.append("phone_number", phone);
        formdata.append("password", password);
        formdata.append("user_type", selected);
        formdata.append("name", name);
        formdata.append("more.business_name", "");
        formdata.append("more.county", "");
        formdata.append("more.sub_county", "");
        formdata.append("more.business_location", "");
        formdata.append("more.business_bio", "");

        const requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        const response = await fetch(
          "https://globalbiva.co.ke/api/business-owners/",
          requestOptions
        );
        const result = await response.json();

        if (result && result.id) {
          console.log(result, result.id);
          setNavigateTo(`/finish-setup-business/${result.id}`);
        } else {
          console.error("Sign up failed", result);
        }
      } catch (error) {
        console.error("Sign up failed", error);
      }
    } else {
      console.error("Please fill in all fields correctly.");
    }
  };

  return (
    <div className="bg-black text-white min-h-screen flex flex-col items-center justify-center">
      <AuthenticationHeader />
      <main className="max-w-screen-lg w-3/4 flex flex-col items-start py-12 px-4 sm:px-6 lg:px-8">
        <h1 className="text-xl mb-8 text-left">Register an account with us</h1>
        <div className="bg-[#1a120e] p-8 rounded-md w-full">
          <h2 className="text-center mb-4 text-lg">
            Which of the following describes you?
          </h2>
          <div className="flex items-center flex-col">
            <SelectButton
              texts={[
                "Business Owner",
                "Retail Customer",
                "Beauty Professional",
              ]}
              selected={selected}
              setSelected={setSelected}
            />
          </div>

          <form
            className="mt-8 space-y-6 flex flex-col items-center"
            onSubmit={handleSignUp}
          >
            <div className="flex flex-col md:flex-row md:space-x-4 w-3/4">
              <InputField
                label="User name"
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                />
              <InputField
                label="Email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <InputField
                label="Phone"
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="flex flex-col md:flex-row md:space-x-4 w-3/4">
              <InputField
                label="Password"
                type="password"
                name="password"
                value={password}
                autoComplete="new-password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputField
                label="Confirm Password"
                type="password"
                name="confirmPassword"
                value={confirmPassword}
                autoComplete="new-password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-center mt-6">
              <input
                type="checkbox"
                name="terms"
                id="terms"
                className="mr-2"
                checked={isTermsChecked}
                onChange={(e) => setIsTermsChecked(e.target.checked)}
              />
              <label htmlFor="terms" className="text-sm">
                By signing in to this app you agree to <br /> the
                <span className="text-red">Terms of Service</span> and
                acknowledge <br /> the{" "}
                <span className="text-red">Privacy Policy</span>
              </label>
            </div>
            <Button
              type="submit"
              className="bg-red text-white rounded-md px-2 py-1 md:px-4 md:py-2 w-24 md:w-36"
              text="Register"
            />
          </form>
          <p className="text-center mt-4">
            Already have an account? <Link className="text-red">Login</Link>
          </p>
          <div className="flex items-center my-6">
            <hr className="flex-1 border-gray-600" />
            <span className="px-4 text-white">Or Continue with</span>
            <hr className="flex-1 border-gray-600" />
          </div>
          <div className="flex justify-center space-x-4">
            <a href="https://www.google.com/webhp?hl=en&sa=X&ved=0ahUKEwjZ69yj5biGAxWqg_0HHRfnAwgQPAgJ">
              <img
                src={googleIcon}
                width="40"
                height="40"
                alt="Google"
                className="bg-[#52443d] p-2 rounded-md"
              />
            </a>
            <a href="#">
              <img
                src={appleIcon}
                width="40"
                height="40"
                alt="Apple"
                className="bg-[#52443d] p-2 rounded-md"
              />
            </a>
            <a href="#">
              <img
                src={twitterIcon}
                width="40"
                height="40"
                alt="Twitter"
                className="bg-[#52443d] p-2 rounded-md"
              />
            </a>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SignUpPage;
