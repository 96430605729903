import React, { useEffect, useRef, useState } from "react";

const CountingNumber = ({ endValue, duration = 2.5, delay = 0 }) => {
  const [count, setCount] = useState(0);
  const countRef = useRef(null);

  useEffect(() => {
    let startTime = null;

    const updateCount = (currentTime) => {
      if (!startTime) startTime = currentTime;
      const elapsedTime = currentTime - startTime;
      const progress = elapsedTime / (duration * 1000);
      const currentCount = Math.min(Math.floor(endValue * progress), endValue);
      setCount(currentCount);

      if (currentCount < endValue) {
        countRef.current = requestAnimationFrame(updateCount);
      }
    };

    setTimeout(() => {
      countRef.current = requestAnimationFrame(updateCount);
    }, delay * 1000);

    return () => {
      cancelAnimationFrame(countRef.current);
    };
  }, [endValue, duration, delay]);

  return <span className="text-4xl md:text-5xl text-red">{count}+</span>;
};

export default CountingNumber;
