import './App.css';
import SharedRoutes from './routes/SharedRoutes';
import BusinessOwnerRoutes from './routes/BusinessOwnerRoutes';
import ProfessionalRoutes from './routes/ProfessionalRoutes';
import CustomerRoutes from './routes/CustomerRoutes';

function App() {
  return (
    <div className="bg-black min-h-screen">
        <SharedRoutes />
        <BusinessOwnerRoutes />
        <ProfessionalRoutes />
        <CustomerRoutes />
    </div>
  );
}

export default App;
