import React, { useState, useEffect } from "react";
import logo from "../../assets/logonew.svg";
import { Link } from "react-router-dom";
import { IoMdNotificationsOutline } from "react-icons/io";
import { MdOutlineAccountCircle } from "react-icons/md";
import NotificationComponent from "../shared/Notification";
import Cookies from "js-cookie";

const NavBar = () => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);

  // Fetch the token from cookies
  const token = Cookies.get("accessToken");

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await fetch(
          "https://api.globalbiva.co.ke/api/notifications/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch notifications");
        }

        const data = await response.json();
        setNotifications(data.results || []);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [token]);

  const markAllAsRead = async () => {
    try {
      const response = await fetch(
        "https://api.globalbiva.co.ke/api/notifications/mark-all-as-read/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        // Update the state to mark all notifications as read
        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) => ({
            ...notification,
            read: true,
          }))
        );
      } else {
        throw new Error("Failed to mark all notifications as read");
      }
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  const markAsRead = async (id) => {
    try {
      const response = await fetch(
        `https://api.globalbiva.co.ke/api/notifications/${id}/mark-as-read/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        // Update the state to mark this specific notification as read
        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) =>
            notification.id === id
              ? { ...notification, read: true }
              : notification
          )
        );
      } else {
        throw new Error(`Failed to mark notification ${id} as read`);
      }
    } catch (error) {
      console.error(`Error marking notification ${id} as read:`, error);
    }
  };

  const clearNotifications = () => {
    setNotifications([]);
    setShowNotifications(false);
  };

  const unreadCount = notifications.filter(
    (notification) => !notification.read
  ).length;

  return (
    <div className="w-full bg-[#1a120e]">
      <div className="flex justify-between items-center max-w-7xl mx-auto py-4 px-4">
        <div>
          <img src={logo} alt="logo" className="h-8 md:h-10" />
        </div>

        <div className="flex items-center space-x-8">
          <Link to="/professional-home" className="text-base font-medium">
            Home
          </Link>
          <Link
            to="/professional-requests"
            className="text-[#f0dfd8] text-base font-medium"
          >
            Requests
          </Link>
          <Link
            to="/professional-employer"
            className="text-[#f0dfd8] text-base font-medium"
          >
            Employer
          </Link>
        </div>

        {/* Icons */}
        <div className="flex items-center space-x-8 relative">
          <div className="relative">
            <IoMdNotificationsOutline
              alt="notifications"
              className="h-6 w-6 cursor-pointer"
              onClick={() => setShowNotifications(true)}
            />
            {unreadCount > 0 && (
              <span className="absolute -top-3 bg-rose-500 -right-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                {unreadCount}
              </span>
            )}
          </div>

          <Link to="/professional-profile">
            <MdOutlineAccountCircle alt="account" className="h-6 w-6" />
          </Link>
        </div>
      </div>

      {showNotifications && (
        <NotificationComponent
          notifications={notifications}
          onClose={() => setShowNotifications(false)}
          markAllAsRead={markAllAsRead}
          clearNotifications={clearNotifications}
          markAsRead={markAsRead} // Pass the markAsRead function
        />
      )}
    </div>
  );
};

export default NavBar;
