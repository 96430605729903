import React from 'react';
import { useNavigate } from 'react-router-dom';

const ApprovedAppointmentCard = ({ appointment }) => {
  const navigate = useNavigate();

  const handleConfirmAppointment = () => {
    // Logic to confirm the appointment
    alert(`Appointment with ${appointment.vendorName} confirmed`);
  };

  const handleLeaveAReview = () => {
    // navigate(/leave-a-review/${appointment.vendor});
  };

  return (
    <div className="bg-[#1a120e] w-full p-4 rounded-lg border border-[#52443d] flex flex-col mb-4">
      <h2 className="text-lg text-white font-semibold">
        {appointment.serviceName}
      </h2>
      <p className="text-[#dcc6c2]">With {appointment.vendorName}</p>
      <p className="text-[#dcc6c2]">
        {new Date(appointment.booking_date).toLocaleDateString()}
      </p>

      <div className="mt-4 flex space-x-2">
        {appointment.status === "confirmed" && (
          <button
            onClick={handleConfirmAppointment}
            className="border-red border-2 text-white px-4 py-2 rounded-md"
          >
            Confirm Appointment
          </button>
        )}
        {appointment.status === "completed" && (
          <button
            onClick={handleLeaveAReview}
            className="bg-red text-white px-4 py-2 rounded-md"
          >
            Leave a Review
          </button>
        )}
      </div>
    </div>
  );
};

export default ApprovedAppointmentCard;