import React, { useEffect, useState } from "react";
import NavBar from "../../components/businessOwner/NavBar";
import { IoIosArrowForward } from "react-icons/io";
import encryption from "../../assets/encrypted.png";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";

const Profile = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});

  const fetchUser = async () => {
    const token = Cookies.get("accessToken");

    if (token) {
      const decoded = jwtDecode(token);
      const userId = decoded.id;

      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://api.globalbiva.co.ke/api/business-owners/${userId}/`,
        requestOptions
      );

      const data = await response.json();
      setUser(data);
    } else {
      alert("An error occurred. Please try again.");
    }
  };

  const logout = () => {
    Cookies.remove("accessToken");
    Cookies.remove("csrftoken");
    navigate("/");
  };
  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <div className="min-h-screen bg-black text-white flex flex-col items-center">
      <NavBar />
      {user && (
        <div className="max-w-screen-lg w-3/4 flex flex-col items-start py-12 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col w-full mb-8 space-y-4">
            <h2 className="text-lg font-semibold">Account</h2>
            <div className=" w-full flex items-center p-4 justify-between">
              <div className="w-1/2 flex items-center border-b border-[#52443d] justify-between">
                <div className="flex flex-row items-center">
                  <img
                    src={user.profile_pic}
                    alt="Profile"
                    className="h-8 w-8 m-2"
                  />
                  <div className="flex flex-col items-start justify-center p-4 w-full">
                    <p className="font-medium">{user.name}</p>
                    <p className="text-sm">Show Profile</p>
                  </div>
                </div>
                <Link to="/account-settings">
                  <IoIosArrowForward className="h-6 w-6" />
                </Link>
              </div>
            </div>
          </div>

          <div className="w-full mb-8 space-y-4 flex flex-col">
            <h2 className="text-lg font-semibold">Settings</h2>
            <div className="flex items-center rounded-lg w-full justify-between">
              <div className="w-1/2 flex items-center mr-4 p-4 border-b border-[#52443d] justify-between">
                <div className="flex items-center space-x-4">
                  <img
                    src={encryption}
                    alt="Reset Password"
                    className="h-8 w-8"
                  />
                  <p className="font-medium">Reset Password</p>
                </div>
                <Link to="/reset-password">
                  <IoIosArrowForward className="h-6 w-6" />
                </Link>
              </div>
              <div
                className="w-1/2 flex items-center mx-8 p-4 border-b border-[#52443d] justify-between cursor-pointer"
                onClick={logout}
              >
                <p className="font-medium">Log out</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
