import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { IoIosRadioButtonOff, IoIosRadioButtonOn } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const ReasonItem = ({ reason, selectedReasons, handleReasonSelect }) => {
  return (
    <div
      className="flex justify-between border-b border-[#52443d] p-4 items-center cursor-pointer"
      onClick={() => handleReasonSelect(reason)}
    >
      <p className="text-base font-light">{reason}</p>
      {selectedReasons.includes(reason) ? (
        <IoIosRadioButtonOn className="w-6 h-6" />
      ) : (
        <IoIosRadioButtonOff className="w-6 h-6" />
      )}
    </div>
  );
};

const ReportBusiness = () => {
  const navigate = useNavigate();
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [customReason, setCustomReason] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleReasonSelect = (reason) => {
    if (reason === "Something else") {
      setSelectedReasons([reason]);
      setIsButtonDisabled(false);
    } else {
      setSelectedReasons((prevSelectedReasons) =>
        prevSelectedReasons.includes(reason)
          ? prevSelectedReasons.filter((r) => r !== reason)
          : [...prevSelectedReasons, reason]
      );
      setIsButtonDisabled(false);
    }
  };

  const handleSubmit = async () => {
    if (selectedReasons.length === 0) {
      return;
    }

    const reportReasons = selectedReasons.includes("Something else")
      ? [...selectedReasons, customReason].filter(
          (reason) => reason !== "Something else"
        )
      : selectedReasons;

    try {
      console.log("Report submitted:", reportReasons);
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };

  const reasons = [
    "It's a scam",
    "It's offensive",
    "It's inaccurate or incorrect",
    "Quality services were not delivered",
    "Something else",
  ];

  return (
    <div className="min-h-screen bg-black text-white flex justify-center items-center">
      <div className="bg-[#1a120e] p-8 rounded-lg w-full max-w-lg space-y-8">
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft />
          <span>Back</span>
        </div>

        <div className="space-y-4">
          <h2 className="text-2xl font-normal">
            Why are you reporting this business?
          </h2>
          <h1 className=" text-base">This won't be shared with the company</h1>

          {reasons.map((reason) => (
            <ReasonItem
              key={reason}
              reason={reason}
              selectedReasons={selectedReasons}
              handleReasonSelect={handleReasonSelect}
            />
          ))}

          {selectedReasons.includes("Something else") && (
            <div className="mt-4">
              <textarea
                className="w-full p-2 rounded-md bg-[#2c2a28] text-white"
                placeholder="Please specify your reason..."
                value={customReason}
                onChange={(e) => setCustomReason(e.target.value)}
              />
            </div>
          )}

          <button
            onClick={handleSubmit}
            className={`bg-red text-white w-full py-3 rounded-md ${
              selectedReasons.length === 0 ||
              (selectedReasons.includes("Something else") &&
                customReason.trim() === "")
                ? "cursor-not-allowed opacity-50"
                : "cursor-pointer"
            }`}
            disabled={
              selectedReasons.length === 0 ||
              (selectedReasons.includes("Something else") &&
                customReason.trim() === "")
            }
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportBusiness;
