import React from "react";
import { Routes, Route } from "react-router-dom";

import FinishBusinessSetup from "../pages/businessOwner/FinishBusinessSetup";
import Home from "../pages/businessOwner/Home";
import SignUpPage from "../pages/businessOwner/SignUp";
import OverviewPage from "../pages/businessOwner/OverviewPage";
import Employees from "../pages/businessOwner/Employees";
import Profile from "../pages/businessOwner/Profile";
import ReportProfessional from "../pages/businessOwner/ReportProfessional";
import AccountSettings from "../pages/businessOwner/AccountSettings";

const BusinessOwnerRoutes = () => (
  <Routes>
    <Route path="/sign-up-business-owner" element={<SignUpPage />} />
    <Route
      path="/finish-setup-business/:userID"
      element={<FinishBusinessSetup />}
    />
    <Route path="/business-dashboard" element={<Home />} />
    <Route path="/overviewpage/:id" element={<OverviewPage />} />
    <Route path="/employees" element={<Employees />} />
    <Route path="/business-profile" element={<Profile />} />
    <Route path="/report-professional/:userId" element={<ReportProfessional />} />
    <Route path="/account-settings" element={<AccountSettings />} />
  </Routes>
);

export default BusinessOwnerRoutes;
