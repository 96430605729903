import React, { useEffect, useState } from "react";
import NavBar from "../../components/businessOwner/NavBar";
import starImage from "../../assets/icons-star.svg";
import backIcon from "../../assets/icon.svg";
import customerAvatar from "../../assets/ellipse-446-4.svg";
import encryptedIcon from "../../assets/encrypted.png";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const AccountSettings = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const authToken = Cookies.get("accessToken");
        if (!authToken) {
            alert("An error occurred. Kindly login again.");
            return;
        }

        const userId = jwtDecode(authToken).id;

        const requestOptions = {
          method: "GET",
          redirect: "follow",
        };

        const response = await fetch(
          `https://api.globalbiva.co.ke/api/users/${userId}/`,
          requestOptions
        );
        const data = await response.json();
        setUser(data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchServices = async () => {
      try {
        const requestOptions = {
          method: "GET",
          redirect: "follow",
        };

        const response = await fetch(
          "https://api.globalbiva.co.ke/api/services/",
          requestOptions
        );
        const data = await response.json();
        setServices(data.results);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUser();
    fetchServices();
  }, []);

  const getServiceNameById = (serviceId) => {
    const service = services.find((s) => s.id === serviceId);
    return service ? service.name : "Unknown Service";
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  const {
    profile_pic,
    name,
    user_type,
    more = {},
    services: userServices = [],
  } = user;
  const { rating, county, sub_county, experience_level, bio } = more;

  const handleBackNavigation = () => {
    return navigate(-1);
  };

//   const handleRequest = async () => {
//     try {
//       const authToken = Cookies.get("accessToken");
//       if (!authToken) {
//         alert("An error occurred. Kindly login again.");
//         return;
//       }

//       const businessID = jwtDecode(authToken).id;
//       const formdata = new FormData();
//       formdata.append("business", businessID);
//       formdata.append("employee", userId);
//       formdata.append("employment_start_date", date);

//       const requestOptions = {
//         method: "POST",
//         body: formdata,
//         redirect: "follow",
//       };

//       const response = await fetch(
//         "https://api.globalbiva.co.ke/api/business-employees/",
//         requestOptions
//       );

//       if (response.ok) {
//         alert("Employee request sent successfully!");
//       } else {
//         alert("Failed to send employee request");
//       }
//     } catch (error) {
//       console.error("An error occurred:", error);
//     }
//   };

  return (
    <div className="min-h-screen w-screen bg-black text-white flex items-center flex-col overflow-x-hidden">
      <NavBar />
      <div className="m-8 w-10/12">
        <Link to="/customer-home" onClick={handleBackNavigation}>
          <img src={backIcon} alt="Back" className="h-4 w-4 cursor-pointer" />
        </Link>
      </div>
      <main className="flex items-start flex-col  max-w-screen-lg px-4 sm:px-6 lg:px-8 mt-8 overflow-x-hidden">
        <div className="flex flex-col w-full md:flex-row md:items-center bg-[#1a120e] p-4 rounded-lg space-y-4 md:space-y-0 md:space-x-4">
          <img src={profile_pic} alt={name} className="h-24 w-24 rounded-lg" />

          <div className="flex-grow">
            <h1 className="text-xl font-semibold">{name}</h1>
            <h2 className="text-lg font-medium">{user_type}</h2>
            <span className="text-sm text-[#d7c2b9ff]">
              {sub_county}, {county}
            </span>
            <br />
            <span className="text-sm text-[#d7c2b9ff]">{bio}</span>
          </div>

          <div className="flex flex-col md:flex-col items-center bg-[#322824] p-4 rounded-lg space-y-2">
            <div className="flex items-center space-x-1">
              <span className="text-lg">{rating}</span>
              <div className="flex space-x-1 text-red-500">
                {[...Array(5)].map((_, i) => (
                  <img
                    key={i}
                    src={starImage}
                    alt="star"
                    className={`h-4 w-4 ${i < rating ? "text-red-500" : ""}`}
                  />
                ))}
              </div>
            </div>
            <p className="text-sm">{experience_level}</p>
            <p className="text-sm">239 Reviews</p>
          </div>
        </div>

        <div className="my-4 flex w-full flex-col flex-wrap">
          <h3 className="text-lg font-semibold">Services Offered by {name}</h3>
          <div className="flex w-full flex-wrap space-x-4 space-y-2 mt-2">
            {userServices.map((serviceId, index) => (
              <div
                key={index}
                className="flex items-center space-x-2 bg-[#1a120e] p-2 rounded-lg"
              >
                <img src={encryptedIcon} alt="service" className="h-6 w-6" />
                <span>{getServiceNameById(serviceId)}</span>
              </div>
            ))}
          </div>
        </div>

        <hr className="w-full border-[#52443d] my-4" />

        <div className="my-4 space-y-4">
          <h3 className="text-lg font-semibold">Rating: {rating}</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* review card */}
            <div className="flex flex-col bg-[#1a120e] p-4 rounded-lg space-y-4">
              <div className="flex items-center space-x-4">
                <img
                  src={customerAvatar}
                  alt="customer"
                  className="h-10 w-10 rounded-full"
                />
                <div>
                  <p className="text-sm font-semibold">Loyve</p>
                  <p className="text-xs text-[#f0dfd8ff]">
                    Customer, 3 years on Biva
                  </p>
                </div>
              </div>
              <p className="text-sm">
                A beautiful company, however the service quality can be improved
                without necessarily increasing cost ridiculously.
              </p>
              <div className="flex justify-between items-center">
                <span className="text-xs">1 year ago</span>
                <div className="flex space-x-1 text-red-500">
                  {[...Array(5)].map((_, i) => (
                    <img
                      key={i}
                      src={starImage}
                      alt="star"
                      className="h-3 w-3"
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="flex flex-col bg-[#1a120e] p-4 rounded-lg space-y-4">
              <div className="flex items-center space-x-4">
                <img
                  src={customerAvatar}
                  alt="customer"
                  className="h-10 w-10 rounded-full"
                />
                <div>
                  <p className="text-sm font-semibold">Loyve</p>
                  <p className="text-xs text-[#f0dfd8ff]">
                    Customer, 3 years on Biva
                  </p>
                </div>
              </div>
              <p className="text-sm">
                A beautiful company, however the service quality can be improved
                without necessarily increasing cost ridiculously.
              </p>
              <div className="flex justify-between items-center">
                <span className="text-xs">1 year ago</span>
                <div className="flex space-x-1 text-red-500">
                  {[...Array(5)].map((_, i) => (
                    <img
                      key={i}
                      src={starImage}
                      alt="star"
                      className="h-3 w-3"
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="flex flex-col bg-[#1a120e] p-4 rounded-lg space-y-4">
              <div className="flex items-center space-x-4">
                <img
                  src={customerAvatar}
                  alt="customer"
                  className="h-10 w-10 rounded-full"
                />
                <div>
                  <p className="text-sm font-semibold">Loyve</p>
                  <p className="text-xs text-[#f0dfd8ff]">
                    Customer, 3 years on Biva
                  </p>
                </div>
              </div>
              <p className="text-sm">
                A beautiful company, however the service quality can be improved
                without necessarily increasing cost ridiculously.
              </p>
              <div className="flex justify-between items-center">
                <span className="text-xs">1 year ago</span>
                <div className="flex space-x-1 text-red-500">
                  {[...Array(5)].map((_, i) => (
                    <img
                      key={i}
                      src={starImage}
                      alt="star"
                      className="h-3 w-3"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <button className="bg-transparent border border-red text-white rounded-lg py-2 px-4">
            Show all 213 reviews
          </button>
        </div>
        <hr className="w-full border-[#52443d] my-4" />


      </main>
    </div>
  );
};

export default AccountSettings;
