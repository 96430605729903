import React, { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { toast } from "react-toastify";
import { IoSearch } from "react-icons/io5";

const SearchBar = () => {
  const [serviceCategories, setServiceCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const experienceLevels = ["Beginner", "Intermediate", "Expert"];
  const [selectedExperienceLevel, setSelectedExperienceLevel] = useState("");
  const ratingLevels = ["2 stars and below", "3 stars", "4 stars and above"];
  const [selectedRatingLevel, setSelectedRatingLevel] = useState("");

  const [activeDropdown, setActiveDropdown] = useState(null); // This will track which dropdown is active

  const fetchServiceCategories = async () => {
    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://api.globalbiva.co.ke/api/service-categories/",
        requestOptions
      );

      const result = await response.json();
      setServiceCategories(result.results || []);
    } catch (error) {
      console.log(error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  useEffect(() => {
    fetchServiceCategories();
  }, []);

  const toggleDropdown = (dropdown) => {
    // If the same dropdown is clicked, close it. Otherwise, open the new one.
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setActiveDropdown(null); // Close all dropdowns after selecting
  };

  const handleExperienceSelect = (experience) => {
    setSelectedExperienceLevel(experience);
    setActiveDropdown(null); // Close all dropdowns after selecting
  };

  const handleRatingSelect = (rating) => {
    setSelectedRatingLevel(rating);
    setActiveDropdown(null); // Close all dropdowns after selecting
  };

  return (
    <div className="bg-[#322824] flex justify-center items-center gap-4 mb-8 flex-wrap rounded-xl w-fit px-4 py-2">
      <div className="bg-[#322824] p-4 flex border-r-2 border-[#dcc6c2] flex-col items-left w-full sm:w-auto">
        <label className="text-[#dcc6c2] text-sm font-normal">Where?</label>
        <select className="text-[#dcc6c2] bg-[#322824] text-xs mt-1 appearance-none">
          <option value="">Select</option>
          <option value="nairobi">Nairobi</option>
          <option value="kisumu">Kisumu</option>
          <option value="mombasa">Mombasa</option>
        </select>
      </div>
      <div className="relative bg-[#322824] p-4 border-r-2 border-[#dcc6c2] flex flex-col items-left w-full sm:w-auto">
        <span className="text-[#dcc6c2] text-sm font-normal">Category</span>
        <span
          className="text-[#dcc6c2] text-xs mt-1 cursor-pointer"
          onClick={() => toggleDropdown("category")}
        >
          {selectedCategory ? selectedCategory.name : "Select Category"}
        </span>
        {activeDropdown === "category" && (
          <div className="absolute top-full left-0 mt-2 w-80 bg-[#1a120e] p-4 rounded-lg shadow-lg z-10">
            <p className="text-white font-light text-base mb-4">
              Service Category
            </p>
            <div className="flex flex-wrap gap-2">
              {serviceCategories.length > 0 ? (
                serviceCategories.map((category, index) => (
                  <div
                    key={index}
                    className={`px-3 py-1 rounded-lg cursor-pointer flex items-center ${
                      selectedCategory && selectedCategory.id === category.id
                        ? "bg-[#614d3c] text-white"
                        : "border border-[#52443d] text-[#dcc6c2]"
                    }`}
                    onClick={() => handleCategorySelect(category)}
                  >
                    {category.name}
                    {selectedCategory &&
                      selectedCategory.id === category.id && (
                        <AiOutlineCheck className="ml-2" />
                      )}
                  </div>
                ))
              ) : (
                <p className="text-[#dcc6c2] text-sm">No categories found.</p>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="relative bg-[#322824] p-4 border-r-2 border-[#dcc6c2] flex flex-col items-left w-full sm:w-auto">
        <span className="text-[#dcc6c2] text-sm font-normal">Experience</span>
        <span
          className="text-[#dcc6c2] text-xs mt-1 cursor-pointer"
          onClick={() => toggleDropdown("experience")}
        >
          {selectedExperienceLevel
            ? selectedExperienceLevel
            : "Select Experience Level"}
        </span>
        {activeDropdown === "experience" && (
          <div className="absolute top-full left-0 mt-2 w-80 bg-[#1a120e] p-4 rounded-lg shadow-lg z-10">
            <p className="text-white font-light text-base mb-4">
              Level of Experience
            </p>
            <div className="flex flex-wrap gap-2">
              {experienceLevels.map((experience, index) => (
                <div
                  key={index}
                  className={`px-3 py-1 rounded-lg cursor-pointer flex items-center ${
                    selectedExperienceLevel === experience
                      ? "bg-[#614d3c] text-white"
                      : "border border-[#52443d] text-[#dcc6c2]"
                  }`}
                  onClick={() => handleExperienceSelect(experience)}
                >
                  {experience}
                  {selectedExperienceLevel === experience && (
                    <AiOutlineCheck className="ml-2" />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="relative bg-[#322824] p-4 flex flex-col items-left w-full sm:w-auto">
        <span className="text-[#dcc6c2] text-sm font-normal">Ratings</span>
        <span
          className="text-[#dcc6c2] text-xs mt-1 cursor-pointer"
          onClick={() => toggleDropdown("rating")}
        >
          {selectedRatingLevel ? selectedRatingLevel : "Select Rating Level"}
        </span>
        {activeDropdown === "rating" && (
          <div className="absolute top-full left-0 mt-2 w-80 bg-[#1a120e] p-4 rounded-lg shadow-lg z-10">
            <p className="text-white font-light text-base mb-4">Ratings</p>
            <div className="flex flex-wrap gap-2">
              {ratingLevels.map((rating, index) => (
                <div
                  key={index}
                  className={`px-3 py-1 rounded-lg cursor-pointer flex items-center ${
                    selectedRatingLevel === rating
                      ? "bg-[#614d3c] text-white"
                      : "border border-[#52443d] text-[#dcc6c2]"
                  }`}
                  onClick={() => handleRatingSelect(rating)}
                >
                  {rating}
                  {selectedRatingLevel === rating && (
                    <AiOutlineCheck className="ml-2" />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="bg-[#322824] p-4 rounded-lg flex items-center w-full sm:w-auto">
        <IoSearch className="h-5 w-5" />
      </div>
    </div>
  );
};

export default SearchBar;
