import React, { useState } from "react";
import NavBar from "../../components/customers/NavBar";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    if (!email) {
      setError("Please enter your email address.");
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(
        "https://api.globalbiva.co.ke/api/password_reset/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.detail || "Failed to send reset password request"
        );
      }

      // If successful, navigate to OTP verification page
      navigate("/otp-verification", { state: { email } });
    } catch (err) {
      toast.error(err.message || "An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="min-h-screen bg-black text-white flex flex-col items-center">
        <NavBar />
        <main className="max-w-screen-lg w-3/4 flex flex-col items-start py-12 px-4 sm:px-6 lg:px-8">
          <h1 className="text-2xl font-semibold">Reset Password</h1>

          <form
            onSubmit={handleResetPassword}
            className="flex items-center flex-col space-y-4 p-8 w-full max-w-4xl"
          >
            <div className="bg-[#1a120e] w-1/2 border border-[#52443d] rounded-lg p-4">
              <input
                type="email"
                placeholder="Email Address"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-transparent text-white w-full outline-none"
              />
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className={`bg-red text-white py-2 px-8 rounded-lg ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isLoading}
              >
                {isLoading ? "Sending..." : "Reset Password"}
              </button>
            </div>
          </form>
        </main>
      </div>
    </>
  );
};

export default ResetPassword;
