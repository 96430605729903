import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import InputField from "../../components/shared/InputField";
import Button from "../../components/shared/Button";
import googleIcon from "../../assets/flat-color-icons_google.svg";
import appleIcon from "../../assets/cib_apple.svg";
import twitterIcon from "../../assets/twitter.svg";
import AuthenticationHeader from "../../components/shared/AuthenticationHeader";
import { jwtDecode } from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [navigateTo, setNavigateTo] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (navigateTo) {
      navigate(navigateTo);
    }
  }, [navigateTo, navigate]);

  const handleLogin = async (e) => {
    if(!email){
      toast.error("Please enter your email");
      return;
    }
    if(!password){
      toast.error("Please enter your password");
      return;
    }
    if (email && password) {
      try {
        const formdata = new FormData();
        formdata.append("email", email);
        formdata.append("password", password);

        const requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        const response = await fetch(
          "https://api.globalbiva.co.ke/api/oauth/",
          requestOptions
        );
        const data = await response.json();
        console.log(response.body, "response");
        console.log(data.detail, "data");
        console.log(data.access);

        if (response.status !== 200) {
          toast.error(data.detail);
          return;
        }
        if (data.access) {
          const decoded = jwtDecode(data.access);
          console.log(decoded);
          const userType = decoded.user_type;
          Cookies.set("accessToken", data.access, { expires: 1 });

          if (userType === "Business Owner") {
            toast.success("Login successful");
            setNavigateTo("/business-dashboard");
          } else if (userType === "Retail Customer") {
            toast.success("Login successful");
            setNavigateTo("/customer-home");
          } else if (userType === "Beauty Professional") {
            toast.success("Login successful");
            setNavigateTo("/professional-home");
          } else {
            toast.error("Invalid user type");
          }
        }
      } catch (error) {
        console.error("An error occurred", error);
      }
    } else {
      console.error("Please fill in all fields correctly.");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="bg-black text-white min-h-screen flex flex-col items-center">
        <AuthenticationHeader />
        <main className="max-w-screen-lg w-3/4 flex flex-col items-start py-12 px-4 sm:px-6 lg:px-8">
          <h1 className="text-xl lg:text-2xl text-left mb-4 lg:mb-8">
            Welcome Back to your account
          </h1>
          <div className="bg-[#1a120e] p-8 rounded-md w-full">
            <form
              className="space-y-6 mt-8 flex flex-col items-center"
            >
              <InputField
                label="Email"
                type="email"
                name="email"
                value={email}
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <InputField
                label="Password"
                type="password"
                name="password"
                value={password}
                placeholder="Enter your password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                className="bg-red text-white w-1/3 py-2 rounded-md md:px-4 md:py-2  md:w-36"
                text="Continue"
                onClick={(e) => {e.preventDefault();handleLogin()}}
              />
            </form>
            <p className="text-center mt-4">
              Don't have an account?{" "}
              <Link className="text-red" to="/sign-up">
                Sign up
              </Link>
            </p>
            <div className="flex items-center my-6">
              <hr className="flex-1 border-gray-600" />
              <span className="px-4 text-white">Or Continue with</span>
              <hr className="flex-1 border-gray-600" />
            </div>
            <div className="flex justify-center space-x-4">
              <a href="#">
                <img
                  src={googleIcon}
                  width="40"
                  height="40"
                  alt="Google"
                  className="bg-[#52443d] p-2 rounded-md"
                />
              </a>
              <a href="#">
                <img
                  src={appleIcon}
                  width="40"
                  height="40"
                  alt="Apple"
                  className="bg-[#52443d] p-2 rounded-md"
                />
              </a>
              <a href="#">
                <img
                  src={twitterIcon}
                  width="40"
                  height="40"
                  alt="Twitter"
                  className="bg-[#52443d] p-2 rounded-md"
                />
              </a>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Login;
