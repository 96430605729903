import React from "react";

const SelectButton= ({
  texts,
  selected,
  setSelected,
}) => {
  return (
    <div className="flex  flex-wrap justify-between mt-6 p-2 sm:justify-start bg-[#52443d] w-fit items-center rounded-md">
      {texts.map((text) => (
        <button
          className={`${
            selected === text ? "bg-[#f0dfd8] text-[#52443d]" : "text-[#f0dfd8]"
          } px-4 py-2 rounded-md mr-2 mb-2 sm:mr-4 sm:mb-0 sm:w-fit`}
          onClick={() => setSelected(text)}
          key={text}
        >
          {text}
        </button>
      ))}
    </div>
  );
};

export default SelectButton;
