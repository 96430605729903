import React from "react";

const TestimonialCard = ({ name, role, image, text }) => {
  return (
    <div className="p-4 border border-gray-500 rounded bg-tertiary text-white">
      <div className="flex items-center">
        <img src={image} alt={name} className="h-16 w-16 rounded-full" />
        <div className="ml-4">
          <p className="font-bold">{name}</p>
          <p className="text-sm">{role}</p>
        </div>
      </div>
      <p className="mt-4">{text}</p>
    </div>
  );
};

export default TestimonialCard;
