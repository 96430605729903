import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import NavBar from '../../components/customers/NavBar';
import ApprovedAppointmentCard from '../../components/customers/ApprovedAppointmentCard';

function Summary() {
    const [approvedAppointments, setApprovedAppointments] = useState([]);

useEffect(() => {
        const fetchAppointments = async () => {
            const authToken = Cookies.get("accessToken");

            if (!authToken) {
                alert("An error occurred. Kindly login again.");
                return;
            }
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${authToken}`);

            try {
                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                };
                const response = await fetch(
                    "https://api.globalbiva.co.ke/api/user-bookings/",
                    requestOptions
                );

                const data = await response.json();
                console.log("API Response:", data);

                const today = new Date();
                const filteredAppointments = data.results?.filter((appointment) => {
                    const appointmentDate = new Date(appointment.booking_date);
                    return (
                        appointment.status === "confirmed" &&
                        appointmentDate < today
                    );
                });

                setApprovedAppointments(filteredAppointments || []);
            } catch (error) {
                console.error("An error occurred fetching appointments:", error);
            }
        };

        fetchAppointments();
    }, []);

  return (
    <div className="bg-black text-white min-h-screen flex flex-col items-center">
      <NavBar />
      <div className="w-full flex items-center flex-col max-w-screen-lg px-4 sm:px-6 lg:px-8 mt-8">
        <main className="flex w-full items-start flex-col max-w-screen-lg px-4 sm:px-6 lg:px-8 mt-8 overflow-x-hidden">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 w-full">
            {approvedAppointments.map((appointment) => (
              <ApprovedAppointmentCard
                key={appointment.id}
                appointment={appointment}
              />
            ))}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Summary