import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import NavBar from "../../components/customers/NavBar";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

function ProfileDetails() {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    const handleBackNavigation = () => {
        navigate(-1);
    }

    useEffect(() => {
        const accessToken = Cookies.get("accessToken");

        const userID = jwtDecode(accessToken).id;

        const fetchUser = async () => {
            try {
                const requestOptions = {
                    method: "GET",
                    redirect: "follow",
                };

                const response = await fetch(
                  `https://api.globalbiva.co.ke/api/retail-customers/${userID}/`,
                  requestOptions
                );

                if (!response.ok) {
                    alert("An error occurred fetching user data");
                    return;
                }
                const data = await response.json();
                setUser(data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchUser();
    }, []);

    if(!user) {
        return <div>Loading...</div>
    }

    const {
        profile_pic,
        name,
        user_type,
    } = user;
  return (
    <div className="min-h-screen w-screen bg-black text-white flex items-center flex-col overflow-x-hidden">
      <NavBar />
      <div className="m-8 w-10/12">
        <FaArrowLeft
          className="h-4 w-4 cursor-pointer"
          onClick={handleBackNavigation}
        />
      </div>
      <main className="flex items-start flex-col  max-w-screen-lg px-4 sm:px-6 lg:px-8 mt-8 overflow-x-hidden">
        <div className="flex flex-col w-full md:flex-row md:items-center bg-[#1a120e] p-4 rounded-lg space-y-4 md:space-y-0 md:space-x-4">
          <img src={profile_pic} alt={name} className="h-24 w-24 rounded-lg" />

          <div className="flex-grow">
            <h1 className="text-xl font-semibold">{name}</h1>
            <h2 className="text-lg font-medium">{user_type}</h2>
            <br />
          </div>
        </div>
      </main>
    </div>
  );
}

export default ProfileDetails;
