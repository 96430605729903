import React, { useState } from "react";
import NavBar from "../../components/customers/NavBar";
import { useNavigate } from "react-router-dom";

const NewPasswordSet = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = useNavigate();
  const handleResetPassword = async () => {
    alert("OTP sent to email");
    navigate("/otp-verification");
  };
  return (
    <div className="min-h-screen bg-black text-white flex flex-col items-center">
      <NavBar />
      <main className="max-w-screen-lg w-3/4 flex flex-col items-start py-12 px-4 sm:px-6 lg:px-8">
        <h1 className="text-2xl font-semibold">Reset Password</h1>

        <div className="flex items-center flex-col space-y-4 p-8 w-full max-w-4xl">
          {/* <div className="bg-[#1a120e] w-1/2 border border-[#52443d] rounded-lg p-4"> */}
          <div className="flex flex-col md:justify-between items-center md:flex-row md:space-x-4 w-full">
            <input
              placeholder="Enter Password"
              type="password"
              name="password"
              value={password}
              autoComplete="new-password"
              onChange={(e) => setPassword(e.target.value)}
              className="bg-[#1a120e] text-white w-full px-4 py-3 rounded-md border border-[#52443d] outline-none focus:ring-2"
            />
            <input
              placeholder="Confirm Password"
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              autoComplete="new-password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="bg-[#1a120e] text-white w-full px-4 py-3 rounded-md border border-[#52443d] outline-none focus:ring-2"
            />
          </div>
          {/* </div> */}
          <div className="flex justify-center">
            <button
              className="bg-red text-white py-2 px-8 rounded-lg"
              onClick={() => handleResetPassword()}
            >
              Reset Password
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default NewPasswordSet;
