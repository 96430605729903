import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { IoIosRadioButtonOff, IoIosRadioButtonOn } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ReasonItem = ({ reason, selectedReasons, handleReasonSelect }) => {
  return (
    <div
      className="flex justify-between border-b border-[#52443d] p-4 items-center cursor-pointer"
      onClick={() => handleReasonSelect(reason)}
    >
      <p className="text-base font-light">{reason}</p>
      {selectedReasons.includes(reason) ? (
        <IoIosRadioButtonOn className="w-6 h-6" />
      ) : (
        <IoIosRadioButtonOff className="w-6 h-6" />
      )}
    </div>
  );
};

const ReportProfessional = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleReasonSelect = (reason) => {
    setSelectedReasons((prevSelectedReasons) =>
      prevSelectedReasons.includes(reason)
        ? prevSelectedReasons.filter((r) => r !== reason)
        : [...prevSelectedReasons, reason]
    );
    setIsButtonDisabled(false);
  };

  const handleSubmit = async () => {
    if (selectedReasons.length === 0) {
      return;
    }

    try {
      const authToken = Cookies.get("accessToken");
      if (!authToken) {
        toast.error("An error occurred. Kindly login again.");
        return;
      }

      const formdata = new FormData();
      formdata.append("reason", selectedReasons.join(", "));
      formdata.append("reported_user", userId);

      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: formdata,
        redirect: "follow",
      };

      const response = await fetch(
        "https://api.globalbiva.co.ke/api/account-reports/",
        requestOptions
      );

      if (response.ok) {
        toast.success("Report submitted successfully!");
        navigate(-1); // Navigate back after successful submission
      } else {
        toast.error("Failed to submit report");
      }
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };

  const reasons = [
    "It's a scam",
    "It's offensive",
    "It's inaccurate or incorrect",
    "Quality services were not delivered",
    "Something else",
  ];

  return (
    <>
      <ToastContainer />
      <div className="min-h-screen bg-black text-white flex justify-center items-center">
        <div className="bg-[#1a120e] p-8 rounded-lg w-full max-w-lg space-y-8">
          <div
            className="flex items-center space-x-2 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <FaArrowLeft />
            <span>Back</span>
          </div>

          <div className="space-y-4">
            <h2 className="text-2xl font-normal">
              Why are you reporting this business?
            </h2>
            <h1 className=" text-base">
              This won't be shared with the company
            </h1>

            {reasons.map((reason) => (
              <ReasonItem
                key={reason}
                reason={reason}
                selectedReasons={selectedReasons}
                handleReasonSelect={handleReasonSelect}
              />
            ))}

            <button
              onClick={handleSubmit}
              className={`bg-red text-white w-full py-3 rounded-md ${
                selectedReasons.length === 0
                  ? "cursor-not-allowed opacity-50"
                  : "cursor-pointer"
              }`}
              disabled={selectedReasons.length === 0}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportProfessional;
