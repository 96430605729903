import React, { useEffect, useState } from "react";
import {
  FaArrowLeft,
  FaCalendarDay,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import { BsChevronBarRight, BsChevronBarLeft } from "react-icons/bs";
import { IoMdTime, IoMdArrowDropdown } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import counties from "../../assets/data/counties.json";

const ProfessionalBooking = () => {
  const { id: vendorId } = useParams();
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [time, setTime] = useState("");
  const [service, setService] = useState("");
  const [clientName, setClientName] = useState("");
  const [selectedCounty, setSelectedCounty] = useState("");
  const [subCounty, setSubCounty] = useState("");
  const [availableSubCounties, setAvailableSubCounties] = useState([]);
  const [showCountyDropdown, setShowCountyDropdown] = useState(false);
  const [showSubCountyDropdown, setShowSubCountyDropdown] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedHour, setSelectedHour] = useState("00");
  const [selectedMinute, setSelectedMinute] = useState("00");
  const [minTime, setMinTime] = useState("00:00");
  const [availableServices, setAvailableServices] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [error, setError] = useState("");
  const today = new Date().toISOString().split("T")[0];


    const handleCountyChange = (e) => {
      const selected = e.target.value;
      setSelectedCounty(selected);

      const countySubCounties = counties[selected];
      setAvailableSubCounties(countySubCounties || []);
      setSubCounty("");
    };

  useEffect(() => {
    const fetchUserAndServices = async () => {
      try {
        const authToken = Cookies.get("accessToken");
        if (!authToken) {
          setError("Authentication token not found. Please log in again.");
          return;
        }

        const requestOptions = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          redirect: "follow",
        };

        const response = await fetch(
          `https://api.globalbiva.co.ke/api/beauty-professionals/${vendorId}/`,
          requestOptions
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const userData = await response.json();

        console.log(userData.services);
        if (userData && userData.services && userData.services.length > 0) {
        const servicesDetails = await Promise.all(
          userData.services.map(async (serviceId) => {
            const serviceResponse = await fetch(
              `https://api.globalbiva.co.ke/api/services/${serviceId}/`,
              requestOptions
            );
            if (!serviceResponse.ok) {
              throw new Error(`Failed to fetch service with ID: ${serviceId}`);
            }
            return serviceResponse.json();
          })
        );
        
        setAvailableServices(servicesDetails);
        setService(servicesDetails[0]?.id || "")
      } else {
          setError("No services available for this vendor.");
        }
      } catch (error) {
        console.error("Failed to fetch user and services", error);
        setError("Failed to load services. Please try again later.");
      }
    };

    const now = new Date();
    const currentHour = now.getHours();
    setSelectedHour(String(currentHour).padStart(2, "0"));
    setSelectedMinute("00");
    setTime(`${String(currentHour).padStart(2, "0")}:00`);

    setMinTime(
      `${String(currentHour).padStart(2, "0")}:${String(
        now.getMinutes()
      ).padStart(2, "0")}`
    );
    fetchUserAndServices();
  }, [vendorId]);

  useEffect(() => {
    const validateForm = () => {
      if (date && time && service && clientName && selectedCounty && subCounty) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    };

    const selectedDate = new Date(date);
    const today = new Date();

    if (selectedDate.toDateString() === today.toDateString()) {
      const currentHour = today.getHours();
      const currentMinute = today.getMinutes();
      setMinTime(
        `${String(currentHour).padStart(2, "0")}:${String(
          currentMinute
        ).padStart(2, "0")}`
      );
    } else {
      setMinTime("00:00");
    }
    validateForm();
  }, [date, time, service, clientName, selectedCounty, subCounty]);

  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
    setShowCalendar(false);

    // Reset time if the selected date is today and current time is later than selected time
    const today = new Date();
    const selectedDateTime = new Date(selectedDate);
    if (selectedDateTime.toDateString() === today.toDateString()) {
      const currentHour = today.getHours();
      const currentMinute = today.getMinutes();
      const selectedTime = `${selectedHour}:${selectedMinute}`;

      if (
        selectedTime <
        `${String(currentHour).padStart(2, "0")}:${String(
          currentMinute
        ).padStart(2, "0")}`
      ) {
        setSelectedHour(String(currentHour).padStart(2, "0"));
        setSelectedMinute("00");
        setTime(`${String(currentHour).padStart(2, "0")}:00`);
      }
    }
  };

  const handleTimeChange = () => {
    const selectedTime = `${selectedHour}:${selectedMinute}`;
    if (selectedTime >= minTime) {
      setTime(selectedTime);
      setShowTimePicker(false);
    } else {
      alert("Please select a time later than the current time.");
    }
  };

  const incrementHour = () => {
    setSelectedHour((prev) => {
      const newHour = (parseInt(prev) + 1) % 24;
      return String(newHour).padStart(2, "0");
    });
  };

  const decrementHour = () => {
    setSelectedHour((prev) => {
      const newHour = (parseInt(prev) - 1 + 24) % 24;
      return String(newHour).padStart(2, "0");
    });
  };

  const incrementMinute = () => {
    setSelectedMinute((prev) => {
      const newMinute = (parseInt(prev) + 15) % 60;
      return String(newMinute).padStart(2, "0");
    });
  };

  const decrementMinute = () => {
    setSelectedMinute((prev) => {
      const newMinute = (parseInt(prev) - 15 + 60) % 60;
      return String(newMinute).padStart(2, "0");
    });
  };

  const handlePreviousMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const daysInMonth = getDaysInMonth(currentMonth, currentYear);
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleDropdownClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleServiceSelect = (serviceId) => {
    setService(serviceId);
    setShowDropdown(false);
  };

  const handleSubmit = async () => {
    const authToken = Cookies.get("accessToken");

    console.log(authToken);
    if (!authToken) {
      setError("An error occured, Kindly Login again");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${authToken}`);

    const formdata = new FormData();
    formdata.append("booking_date", date);
    formdata.append("booking_time", time);
    formdata.append("county", selectedCounty);
    formdata.append("sub_county", subCounty);
    formdata.append("vendor", vendorId);
    formdata.append("service", service);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://api.globalbiva.co.ke/api/bookings/",
        requestOptions
      );

      if (!response.ok) {
        throw new Error(
          `HTTP error! Status: ${response.status}, ${response.statusText}`
        );
      }
      const result = await response.json();
      alert("Booking submitted successfully:", result);
      navigate("/appointments");
    } catch (error) {
      alert("Error submitting booking, Kindly wait and try again later", error);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white flex justify-center items-center">
      <div className="bg-[#1a120e] p-8 rounded-lg w-full max-w-lg space-y-8">
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft />
          <span>Back</span>
        </div>

        <div className="space-y-4">
          <h2 className="text-2xl font-semibold">Booking Details</h2>
          {error && (
            <div className="bg-red text-white p-2 rounded-md">{error}</div>
          )}
          {/* Date Picker Section */}
          <div className="relative bg-[#1a120e] p-4 rounded-md border border-[#52443d] flex items-center">
            <input
              type="text"
              value={date}
              readOnly
              className="bg-transparent text-white focus:outline-none"
              required
            />
            <FaCalendarDay
              className="absolute right-4 cursor-pointer"
              onClick={() => setShowCalendar(!showCalendar)}
            />
          </div>
          {showCalendar && (
            <div className="bg-[#1c1c1c] p-4 rounded-md">
              <div className="flex justify-between items-center mb-4">
                <BsChevronBarLeft
                  className="h-5 w-5 cursor-pointer"
                  onClick={handlePreviousMonth}
                />
                <span>{`${monthNames[currentMonth]} ${currentYear}`}</span>
                <BsChevronBarRight
                  className="h-5 w-5 cursor-pointer"
                  onClick={handleNextMonth}
                />
              </div>
              <div className="grid grid-cols-7 gap-2">
                <div>Su</div>
                <div>Mo</div>
                <div>Tu</div>
                <div>We</div>
                <div>Th</div>
                <div>Fr</div>
                <div>Sa</div>
                {Array(firstDayOfMonth)
                  .fill(null)
                  .map((_, i) => (
                    <div key={`empty-${i}`} />
                  ))}
                {[...Array(daysInMonth)].map((_, i) => {
                  const day = String(i + 1).padStart(2, "0");
                  const fullDate = `${currentYear}-${String(
                    currentMonth + 1
                  ).padStart(2, "0")}-${day}`;
                  return (
                    <div
                      key={i}
                      className={`cursor-pointer text-center ${
                        fullDate < today
                          ? "text-gray-500 pointer-events-none"
                          : ""
                      } ${date === fullDate ? "bg-red-600 text-white" : ""} ${
                        fullDate < today ? "cursor-not-allowed" : ""
                      }`}
                      onClick={() =>
                        fullDate >= today && handleDateChange(fullDate)
                      }
                    >
                      {i + 1}
                    </div>
                  );
                })}
              </div>
              <button
                className="bg-red-600 text-white py-1 px-4 rounded mt-4 mx-auto block"
                onClick={() => handleDateChange(today)}
              >
                Today
              </button>
            </div>
          )}
          {/* Time Picker */}
          <div className="relative bg-[#1a120e] p-4 rounded-md border border-[#52443d] flex items-center">
            <input
              type="text"
              value={time}
              readOnly
              className="bg-transparent text-white focus:outline-none"
              required
            />
            <IoMdTime
              className="absolute right-4 cursor-pointer"
              onClick={() => setShowTimePicker(!showTimePicker)}
            />
          </div>
          {showTimePicker && (
            <div className="bg-[#1c1c1c] p-4 rounded-md mt-1 z-10">
              <div className="flex justify-around mb-4">
                <div className="flex flex-col items-center">
                  <span>Hours</span>
                  <div className="flex flex-col items-center">
                    <button onClick={incrementHour}>
                      <FaChevronUp />
                    </button>
                    <div className="text-center py-1">{selectedHour}</div>
                    <button onClick={decrementHour}>
                      <FaChevronDown />
                    </button>
                  </div>
                </div>
                <div className="flex flex-col items-center">
                  <span>Minutes</span>
                  <div className="flex flex-col items-center">
                    <button onClick={incrementMinute}>
                      <FaChevronUp />
                    </button>
                    <div className="text-center py-1">{selectedMinute}</div>
                    <button onClick={decrementMinute}>
                      <FaChevronDown />
                    </button>
                  </div>
                </div>
              </div>
              <button
                className="bg-red text-white py-1 px-4 rounded mx-auto block"
                onClick={handleTimeChange}
              >
                Set Time
              </button>
            </div>
          )}{" "}
          {/* Service Type Dropdown */}
          <div className="bg-[#1a120e] p-4 rounded-md border border-[#52443d] relative">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={handleDropdownClick}
            >
              <input
                type="text"
                value={
                  availableServices.find((s) => s.id === service)?.name || ""
                }
                readOnly
                className="bg-transparent text-white focus:outline-none w-full"
                required
              />
              <IoMdArrowDropdown className="h-6 w-6" />
            </div>
          </div>
          {showDropdown && (
            <div className="w-full bg-[#1c1c1c] p-4 rounded-md z-10">
              {availableServices.map((service) => (
                <div
                  key={service.id}
                  className="cursor-pointer py-2"
                  onClick={() => handleServiceSelect(service.id)}
                >
                  {service.name}
                </div>
              ))}
            </div>
          )}
          {/* County Dropdown */}
          <div className="bg-[#1a120e] p-4 rounded-md border border-[#52443d] relative">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => setShowCountyDropdown(!showCountyDropdown)}
            >
              <input
                type="text"
                value={selectedCounty}
                placeholder="Select County"
                readOnly
                className="bg-transparent text-white focus:outline-none w-full"
                required
              />
              <IoMdArrowDropdown className="h-6 w-6" />
            </div>
          </div>
          {showCountyDropdown && (
              <div className="w-full bg-[#1c1c1c] p-4 rounded-md z-10">
                {Object.keys(counties).map((county, index) => (
                  <div
                    key={index}
                    className="cursor-pointer py-2"
                    onClick={() => {
                      setSelectedCounty(county);
                      setShowCountyDropdown(false);
                      handleCountyChange({ target: { value: county } });
                    }}
                  >
                    {county}
                  </div>
                ))}
              </div>
            )}
          {/* Sub-County Dropdown */}
          <div className="bg-[#1a120e] p-4 rounded-md border border-[#52443d] relative">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => setShowSubCountyDropdown(!showSubCountyDropdown)}
            >
              <input
                type="text"
                value={subCounty}
                placeholder="Select Sub-County"
                readOnly
                className="bg-transparent text-white focus:outline-none w-full"
                required
                disabled={!availableSubCounties.length}
              />
              <IoMdArrowDropdown className="h-6 w-6" />
            </div>
            
          </div>
          {showSubCountyDropdown && (
              <div className="w-full bg-[#1c1c1c] p-4 rounded-md z-10">
                {availableSubCounties.map((subCounty, index) => (
                  <div
                    key={index}
                    className="cursor-pointer py-2"
                    onClick={() => {
                      setSubCounty(subCounty);
                      setShowSubCountyDropdown(false);
                    }}
                  >
                    {subCounty}
                  </div>
                ))}
              </div>
            )}
          {/* Client Name Input */}
          <div className="bg-[#1a120e] p-4 rounded-md border border-[#52443d] flex items-center">
            <input
              type="text"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
              className="bg-transparent text-white focus:outline-none w-full"
              placeholder="Name of Client"
              required
            />
          </div>
          {/* Submit Button */}
          <button
            onClick={handleSubmit}
            className={`bg-red text-white w-full py-3 rounded-md ${
              isButtonDisabled ? "cursor-not-allowed" : "cursor-pointer"
            }`}
            disabled={isButtonDisabled}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfessionalBooking;
