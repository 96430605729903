import React from "react";
import logo from "../../assets/logonew.svg";
import image1 from "../../assets/onboardingImge4.png";
import image2 from "../../assets/frame-6.svg";
import image3 from "../../assets/Frame 5.png";
import TestimonialImage from "../../assets/frame-18.svg";
import vector from "../../assets/vector.svg";
import Button from "../../components/shared/Button";
import Section from "../../components/shared/Section";
import TestimonialCard from "../../components/shared/TestimonialCard";
import {useNavigate } from "react-router-dom";
import CountingNumber from "../../components/shared/CountingNumber";
import OnboardingHeader from "../../components/shared/OnboardingHeader";


export default function HomePage() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col">
      <OnboardingHeader logo={logo} />
      <main className="bg-purple py-8 md:py-12">
        <div className="px-4 md:px-36 flex flex-col md:flex-row items-center justify-around gap-0">
          <div className="mb-8 md:mb-0 flex justify-center md:block">
            <img
              src={vector}
              alt="Beauty Tools"
              className="w-64 md:w-80 h-64 md:h-80"
            />
          </div>
          <div className="flex flex-col items-center text-center md:text-left">
            <h1 className="text-xl md:text-3xl my-4 text-white leading-tight">
              Connecting talent to <br /> opportunity
            </h1>
            <Button
              text="Contact Us"
              className="bg-black text-white rounded px-2 py-1 md:px-4 md:py-2 w-24 md:w-36"
              onClick={() => navigate("/contact-form")}
            />
          </div>
        </div>
      </main>

      <section
        className="py-8 md:py-12 flex justify-center items-center text-center bg-cover bg-no-repeat bg-center"
        style={{
          backgroundImage: `url(${require("../../assets/ali-mkumbwa-PKcZhrQMqNo-unsplash.jpg")})`,
        }}
      >
        <div
          className="w-full md:w-1/2 px-4"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
        >
          <div>
            <h2 className="text-3xl md:text-4xl font-semibold text-red mb-8">
              Who We Are
            </h2>
            <p className="text-white font-semibold">
              Are you a barber, salonist, or beauty therapist? Are you a
              business owner in the beauty and wellness industry? BIVA is a web
              and app-based solution that helps you connect to jobs if you are a
              beauty professional or to suitable employees if you are a business
              owner. BIVA does this by connecting you to opportunities within
              your area. If you are a freelance/self-employed barber or
              beautician, BIVA helps you to connect with clients directly and
              offer them service within the comfort of their homes or offices.
            </p>
          </div>
          <Button
            text="Sign up"
            className="bg-red text-white rounded px-2 py-1 md:px-4 md:my-4 w-24 md:w-36"
            onClick={() => navigate("/sign-up")}
          />
        </div>
      </section>

      <section className="bg-black py-8 md:py-12">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-around text-center text-red-600 text-sm md:text-xl gap-4">
            <div className="mb-4 md:mb-0">
              <CountingNumber endValue={65} />
              <p className="text-white">Onboarded businesses</p>
            </div>
            <div className="mb-4 md:mb-0">
              <CountingNumber endValue={650} delay={0.5} />
              <p className="text-white">Earning beauty Professionals</p>
            </div>
            <div>
              <CountingNumber endValue={900} delay={1} />
              <p className="text-white">Happy Clients</p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-black py-8 md:py-12">
        <div className="container mx-auto px-4">
          <div className="md:text-3xl text-red mb-8 text-left mx-16">
            Why Businesses Choose us
          </div>
          <Section
            title="Unlock Your Business Potential with Biva"
            content={[
              "Build lasting relationships with reliable professionals and grow your business.",
              "Access a diverse pool of skilled professionals in the beauty industry.",
              "Discover talented individuals who can elevate your services and customer experience.",
            ]}
            image={image3}
          />
        </div>
      </section>

      <section className="bg-black py-8 md:py-12">
        <div className="container mx-auto px-4">
          <div className="md:text-3xl text-red mb-8 text-left mx-16">
            Why Beauty Professionals Choose us
          </div>
          <Section
            title="Showcase Your Skills and Shine on BIVA"
            content={[
              "Create a compelling profile to showcase your skills and experience.",
              "Explore job opportunities from reputable businesses and grow your career.",
              "Receive feedback and build your reputation with ratings and reviews.",
            ]}
            image={image2}
            reverse
          />
        </div>
      </section>

      <section className="bg-black py-8 md:py-12">
        <div className="container mx-auto px-4">
          <h3 className="text-xl md:text-3xl text-red mb-8 text-left mx-16">
            Why Clients Choose us
          </h3>
          <Section
            title="Beauty Services Tailored Just for You"
            content={[
              "Browse a wide range of beauty services offered by skilled professionals.",
              "Read reviews and ratings to make informed decisions and book appointments seamlessly.",
              "Experience convenience and quality with BIVA's user-friendly platform.",
            ]}
            image={image1}
          />
        </div>
      </section>

      <section className="bg-black py-8 md:py-12 mx-16">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl md:text-3xl text-red mb-8 text-left">
            What People Say about Us
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <TestimonialCard
              name="Coleman Steve"
              role="Beauty Professional"
              image={TestimonialImage}
              text="The company was able to provide access to clients for me and this enabled me to improve my life as I had access to important finances."
            />
            <TestimonialCard
              name="Coleman Steve"
              role="Business"
              image={TestimonialImage}
              text="The company was able to provide access to clients for me and this enabled me to improve my life as I had access to important finances."
            />
            <TestimonialCard
              name="Coleman Steve"
              role="Client"
              image={TestimonialImage}
              text="The company was able to provide access to clients for me and this enabled me to improve my life as I had access to important finances."
            />
          </div>
        </div>
      </section>

      <footer className="bg-tertiary py-8 md:py-12 text-white">
        <div className="container mx-auto flex flex-col md:flex-row px-4">
          <div className="flex-1 mb-8 md:mb-0">
            <img src={logo} alt="Biva Logo" className="h-8 mb-4" />
            <p>
              we are a beauty professional company that is aimed at connecting
              stakeholders in the beauty industry
            </p>
          </div>
          <div className="flex-1 mb-8 md:mb-0">
            <h3 className="text-lg md:text-xl text-red mb-4">Quick Links</h3>
            {/* <ul>
              <li className="mb-2">
                <Link to="/">Home</Link>
              </li>
              <li className="mb-2">
                <Link to="/">About Us</Link>
              </li>
              <li className="mb-2">
                <Link to="/">Testimonials</Link>
              </li>
            </ul> */}
          </div>
          <div className="flex-1">
            <h3 className="text-lg md:text-xl text-red mb-4">Contact</h3>
            <p>Email: globalbiva@gmail.com</p>
            <p>Phone: 0740354576</p>
          </div>
        </div>
      </footer>
    </div>
  );
}
