import React, { useEffect, useState } from "react";
import NavBar from "../../components/businessOwner/NavBar";
import starImage from "../../assets/icons-star.svg";
import backIcon from "../../assets/icon.svg";
import flagIcon from "../../assets/flag.svg";
import customerAvatar from "../../assets/ellipse-446-4.svg";
import experienceIcon from "../../assets/health_and_beauty.svg";
import encryptedIcon from "../../assets/encrypted.png";
import Cookies from "js-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaymentModal from "../../components/businessOwner/PaymentModal";

const OverviewPage = () => {
  const { id: userId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [services, setServices] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [reviewers, setReviewers] = useState([]);
  const [showAllReviews, setShowAllReviews] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [hasPaidContact, setHasPaidContact] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const date = new Date().toISOString().split("T")[0];

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(
          `https://api.globalbiva.co.ke/api/beauty-professionals/${userId}/`
        );
        const data = await response.json();
        setUser(data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchServices = async () => {
      try {
        const response = await fetch(
          "https://api.globalbiva.co.ke/api/services/"
        );
        const data = await response.json();
        setServices(data.results);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchReviews = async () => {
      try {
        const response = await fetch(
          `https://api.globalbiva.co.ke/api/professionals-reviews/`
        );
        const data = await response.json();

        const reviewerId = data.results.map((reviewer) => reviewer.reviewer);

        reviewerId.forEach((id) => {
          getReviewerData(id);
        });
        setReviews(data.results);
      } catch (error) {
        console.log(error);
      }
    };

    const getReviewerData = async (reviewerId) => {
      try {
        const response = await fetch(
          `https://api.globalbiva.co.ke/api/users/${reviewerId}/`
        );
        const data = await response.json();
        setReviewers(data);
      } catch (error) {
        console.log(error);
      }
    };

    const authToken = Cookies.get("accessToken");
    const fetchSubscriptionStatus = async () => {
      if (!authToken) {
        return;
      }

      try {
        const requestOptions = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          redirect: "follow",
        };
        const response = await fetch(
          "https://api.globalbiva.co.ke/api/subscription-status/",
          requestOptions
        );
        const data = await response.json();
        setHasSubscription(data.status);
      } catch (error) {
        console.log("Failed to fetch subscription status", error);
      }
    };

    const fetchPaidContactStatus = async () => {
      try {
        const response = await fetch(
          `https://api.globalbiva.co.ke/api/contacts-paid/${userId}/`
        );
        const data = await response.json();
        setHasPaidContact(data.has_paid_contact);
      } catch (error) {
        console.log("Failed to fetch paid contact status", error);
      }
    };

    fetchUser();
    fetchServices();
    fetchReviews();
    fetchSubscriptionStatus();
    fetchPaidContactStatus();
  }, [userId]);

  const getServiceNameById = (serviceId) => {
    const service = services.find((s) => s.id === serviceId);
    return service ? service.name : "Unknown Service";
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  const {
    profile_pic,
    name,
    user_type,
    phone_number, // Assuming the user's phone number is in this field
    more = {},
    services: userServices = [],
  } = user;
  const { rating, county, sub_county, experience_level, bio } = more;

  const handleBackNavigation = () => {
    return navigate(-1);
  };

  const handleRequest = async () => {
    if (hasSubscription || hasPaidContact) {
      try {
        const authToken = Cookies.get("accessToken");
        if (!authToken) {
          toast.error("An error occurred. Kindly login again.");
          return;
        }

        const businessID = jwtDecode(authToken).id;
        const formdata = new FormData();
        formdata.append("business", businessID);
        formdata.append("employee", userId);
        formdata.append("employment_start_date", date);

        const requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        const response = await fetch(
          "https://api.globalbiva.co.ke/api/business-employees/",
          requestOptions
        );

        if (response.ok) {
          toast.success("Employee request sent successfully!");
        } else {
          toast.error("Employee is already added");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    } else {
      setShowPaymentModal(true);
    }
  };

  const fomartDate = (date) => {
    return new Date(date).toLocaleDateString();
  };
  const displayedReviews = showAllReviews ? reviews : reviews?.slice(0, 4);

  return (
    <>
      <ToastContainer />

      <div className="min-h-screen w-screen bg-black text-white flex items-center flex-col overflow-x-hidden">
        <NavBar />
        <div className="m-8 w-10/12">
          <Link to="/customer-home" onClick={handleBackNavigation}>
            <img src={backIcon} alt="Back" className="h-4 w-4 cursor-pointer" />
          </Link>
        </div>
        <main className="flex items-start flex-col max-w-screen-lg px-4 sm:px-6 lg:px-8 mt-8 overflow-x-hidden">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full  p-4 rounded-lg">
            <div className="flex items-center bg-[#322824] rounded-lg border border-[#907c74]">
              <img
                src={profile_pic}
                alt={name}
                className="h-32 w-32 rounded-lg"
              />

              <div className="ml-4 w-full">
                <h1 className="text-xl font-semibold">{name}</h1>
                <h2 className="text-lg font-medium">{user_type}</h2>
                {(sub_county || county) && (
                  <p className="text-sm text-[#d7c2b9ff]">
                    {sub_county && county
                      ? `${sub_county}, ${county}`
                      : sub_county || county}
                  </p>
                )}
                <p className="text-sm text-[#d7c2b9ff] whitespace-normal break-words">
                  {bio}
                </p>
                {hasPaidContact ||
                  (hasSubscription && (
                    <p className="text-base  text-[#ebe5e2] mt-2">
                       {phone_number} 
                    </p>
                  ))}
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4 bg-[#322824] p-4 rounded-lg">
              <div className="flex flex-col items-center">
                <span className="text-lg">{rating}</span>
                <div className="flex space-x-1 text-red-500">
                  {[...Array(5)].map((_, i) => (
                    <img
                      key={i}
                      src={starImage}
                      alt="star"
                      className={`h-4 w-4 ${0 < rating ? "text-red-500" : ""}`}
                    />
                  ))}
                </div>
              </div>
              <div className="flex flex-col items-center">
                <img
                  src={experienceIcon}
                  className="text-sm"
                  alt="experience"
                />
                <p className="text-sm">{experience_level}</p>
              </div>
              <div className="flex flex-col items-center">
                <p className="text-sm">{reviews.length}</p>
                <p className="text-sm">Reviews</p>
              </div>
            </div>
          </div>

          <div className="my-4 w-full">
            <h3 className="text-lg font-semibold">
              Services Offered by {name}
            </h3>
            <div className="flex space-x-4 mt-2">
              {userServices.map((serviceId, index) => (
                <div
                  key={index}
                  className="flex items-center space-x-2 bg-[#1a120e] p-2 rounded-lg"
                >
                  <img src={encryptedIcon} alt="service" className="h-6 w-6" />
                  <span>{serviceId && getServiceNameById(serviceId)}</span>
                </div>
              ))}
            </div>
          </div>

          <hr className="w-full border-[#52443d] my-4" />

          {reviews.length > 0 ? (
            <div className="my-4 w-full ">
              <div className="text-lg w-full font-semibold flex items-center justify-between">
                <div className="flex items-center">
                  <img src={starImage} alt="star" className={`h-4 w-4 `} />
                  {rating}
                </div>
                <div className="text-sm">{reviews.length} reviews</div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                {displayedReviews.map((review, index) => (
                  <div
                    key={index}
                    className="flex flex-col border border-[#907c74] bg-[#1a120e] p-4 rounded-lg space-y-4"
                  >
                    <div className="flex items-center space-x-4">
                      <img
                        src={reviewers.profile_pic}
                        alt="profile"
                        className="h-10 w-10 rounded-full"
                      />
                      <div>
                        <p className="text-sm font-semibold">
                          {reviewers.name}
                        </p>
                        <p className="text-xs text-[#f0dfd8ff]">
                          {reviewers.user_type} on Biva
                        </p>
                      </div>
                    </div>
                    <p className="text-sm ">{review.review_text}</p>
                    <div className="flex justify-between items-center">
                      <span className="text-xs">
                        {fomartDate(review.review_date)}
                      </span>
                      <div className="flex space-x-1 text-red-500">
                        {[...Array(5)].map((_, i) => (
                          <img
                            key={i}
                            src={starImage}
                            alt="star"
                            className="h-3 w-3"
                            style={{
                              filter:
                                i < review.rating
                                  ? "brightness(1)"
                                  : "brightness(0.5)",
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {reviews.length > 4 && (
                <button
                  className="mt-4 bg-transparent border border-red text-white rounded-lg py-2 px-4"
                  onClick={() => setShowAllReviews(!showAllReviews)}
                >
                  {showAllReviews
                    ? "Show Less"
                    : `Show all ${reviews.length} reviews`}
                </button>
              )}
            </div>
          ) : (
            <div className="my-4 w-full h-40 text-center">
              <p className="text-sm">
                When your customers or employees write you a review, it will
                appear here
              </p>
            </div>
          )}

          <hr className="w-full border-[#52443d] my-4" />

          <div className="flex justify-between items-start pt-4">
            <div className="flex items-center space-x-2">
              <img src={flagIcon} alt="report" className="h-4 w-4" />
              <Link to={`/report-professional/${userId}`}>
                <span className="underline">Report this user</span>
              </Link>
            </div>
          </div>

          <div className="my-4">
            <button
              className="bg-red text-white py-2 px-4 rounded-lg"
              onClick={handleRequest}
            >
              Add Employee
            </button>
          </div>
        </main>
        {showPaymentModal && (
          <PaymentModal onClose={() => setShowPaymentModal(false)} />
        )}
      </div>
    </>
  );
};

export default OverviewPage;
