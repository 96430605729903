import React, { useEffect, useState } from "react";
import NavBar from "../../components/jobSeekers/NavBar";
import starImage from "../../assets/icons-star.svg";
import backIcon from "../../assets/icon.svg";
import flagIcon from "../../assets/flag.svg";
import Cookies from "js-cookie";
import customerAvatar from "../../assets/ellipse-446-4.svg";
import encryptedIcon from "../../assets/encrypted.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import PaymentModal from "../../components/shared/PaymentModal";

const BusinessOverview = () => {
  const { id: userId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [services, setServices] = useState([]);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [hasPaidContact, setHasPaidContact] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const requestOptions = {
          method: "GET",
          redirect: "follow",
        };

        const response = await fetch(
          `https://api.globalbiva.co.ke/api/business-owners/${userId}/`,
          requestOptions
        );
        const data = await response.json();
        setUser(data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchServices = async () => {
      try {
        const requestOptions = {
          method: "GET",
          redirect: "follow",
        };

        const response = await fetch(
          "https://api.globalbiva.co.ke/api/services/",
          requestOptions
        );
        const data = await response.json();
        setServices(data.results);
      } catch (error) {
        console.log(error);
      }
    };

    const authToken = Cookies.get("accessToken");
    const fetchSubscriptionStatus = async () => {
      if (!authToken) {
        return;
      }

      try {
        const requestOptions = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          redirect: "follow",
        };
        const response = await fetch(
          "https://api.globalbiva.co.ke/api/subscription-status/",
          requestOptions
        );
        const data = await response.json();
        setHasSubscription(data.status);
      } catch (error) {
        console.log("Failed to fetch subscription status", error);
      }
    };

    const fetchPaidContactStatus = async () => {
      try {
        const response = await fetch(
          `https://api.globalbiva.co.ke/api/contacts-paid/${userId}/`
        );
        const data = await response.json();
        setHasPaidContact(data.has_paid_contact);
      } catch (error) {
        console.log("Failed to fetch paid contact status", error);
      }
    };

    fetchUser();
    fetchServices();
    fetchSubscriptionStatus();
    fetchPaidContactStatus();
  }, [userId]);

  console.log(user);
  console.log(userId);
  const getServiceNameById = (serviceId) => {
    const service = services.find((s) => s.id === serviceId);
    return service ? service.name : "Unknown Service";
  };

  const handleRequest = () => {
    if (hasSubscription || hasPaidContact) {
      // User has an active subscription or has paid for the contact, proceed
      navigate(`/employment-requests/${userId}`);
    } else {
      // User doesn't have an active subscription, show payment modal
      setShowPaymentModal(true);
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  const {
    profile_pic,
    name,
    user_type,
    more = {},
    services_business: userServices = [],
  } = user;
  const {
    rating,
    business_name,
    county,
    sub_county,
    business_location,
    business_bio,
  } = more;

  const handleBackNavigation = () => {
    return navigate(-1);
  };

  return (
    <div className="min-h-screen w-screen bg-black text-white flex items-center flex-col overflow-x-hidden">
      <NavBar />
      <div className="m-8 w-10/12">
        <Link to="/customer-home" onClick={handleBackNavigation}>
          <img src={backIcon} alt="Back" className="h-4 w-4 cursor-pointer" />
        </Link>
      </div>
      <main className="flex items-start flex-col  max-w-screen-lg px-4 sm:px-6 lg:px-8 mt-8 overflow-x-hidden">
        <div className="flex flex-col w-full md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4">
          <div className="bg-[#322824] p-4 rounded-lg flex flex-row space-x-4 justify-between w-full">
            <img
              src={profile_pic}
              alt={name}
              className="h-24 w-24 rounded-lg"
            />
            <div className="flex-grow">
              <h1 className="text-xl font-semibold">{business_name}</h1>
              <h2 className="text-lg font-medium">{user_type}</h2>
              <h3 className="text-lg font-medium">{business_location}</h3>
              <span className="text-sm text-[#d7c2b9ff]">
                {sub_county},{county}
              </span>
              <br />
              <span className="text-sm text-[#d7c2b9ff]">{business_bio}</span>
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row items-center justify-around bg-[#322824] p-4 rounded-lg space-y-2">
            <div className="flex items-center space-x-1">
              <div className="flex items-center flex-col space-x-1">
                <span className="text-lg">{rating}</span>
                <div className="flex space-x-1 text-red">
                  {[...Array(5)].map((_, i) => (
                    <img
                      key={i}
                      src={starImage}
                      alt="star"
                      className={`h-4 w-4 ${i < rating ? "text-red" : ""}`}
                    />
                  ))}
                </div>
              </div>
            </div>
            <p className="text-sm">239 Reviews</p>
          </div>
        </div>

        <div className="my-4 flex w-full flex-col flex-wrap">
          <h3 className="text-lg font-semibold">Services Offered by {name}</h3>
          <div className="flex w-full flex-wrap space-x-4 space-y-2 mt-2">
            {userServices.map((serviceId, index) => (
              <div
                key={index}
                className="flex items-center space-x-2 bg-[#1a120e] p-2 rounded-lg"
              >
                <img src={encryptedIcon} alt="service" className="h-6 w-6" />
                <span>{getServiceNameById(serviceId)}</span>
              </div>
            ))}
          </div>
        </div>

        <hr className="w-full border-[#52443d] my-4" />
        {/* review section */}
        <div className="my-4 space-y-4">
          <h3 className="text-lg font-semibold">Rating: {rating}</h3>
          {/* review card */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col bg-[#1a120e] p-4 rounded-lg space-y-4">
              <div className="flex items-center space-x-4">
                <img
                  src={customerAvatar}
                  alt="customer"
                  className="h-10 w-10 rounded-full"
                />
                <div>
                  <p className="text-sm font-semibold">Loyve</p>
                  <p className="text-xs text-[#f0dfd8ff]">
                    Customer, 3 years on Biva
                  </p>
                </div>
              </div>
              <p className="text-sm">
                A beautiful company, however the service quality can be improved
                without necessarily increasing cost ridiculously.
              </p>
              <div className="flex justify-between items-center">
                <span className="text-xs">1 year ago</span>
                <div className="flex space-x-1 text-red-500">
                  {[...Array(5)].map((_, i) => (
                    <img
                      key={i}
                      src={starImage}
                      alt="star"
                      className="h-3 w-3"
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="flex flex-col bg-[#1a120e] p-4 rounded-lg space-y-4">
              <div className="flex items-center space-x-4">
                <img
                  src={customerAvatar}
                  alt="customer"
                  className="h-10 w-10 rounded-full"
                />
                <div>
                  <p className="text-sm font-semibold">Loyve</p>
                  <p className="text-xs text-[#f0dfd8ff]">
                    Customer, 3 years on Biva
                  </p>
                </div>
              </div>
              <p className="text-sm">
                A beautiful company, however the service quality can be improved
                without necessarily increasing cost ridiculously.
              </p>
              <div className="flex justify-between items-center">
                <span className="text-xs">1 year ago</span>
                <div className="flex space-x-1 text-red-500">
                  {[...Array(5)].map((_, i) => (
                    <img
                      key={i}
                      src={starImage}
                      alt="star"
                      className="h-3 w-3"
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="flex flex-col bg-[#1a120e] p-4 rounded-lg space-y-4">
              <div className="flex items-center space-x-4">
                <img
                  src={customerAvatar}
                  alt="customer"
                  className="h-10 w-10 rounded-full"
                />
                <div>
                  <p className="text-sm font-semibold">Loyve</p>
                  <p className="text-xs text-[#f0dfd8ff]">
                    Customer, 3 years on Biva
                  </p>
                </div>
              </div>
              <p className="text-sm">
                A beautiful company, however the service quality can be improved
                without necessarily increasing cost ridiculously.
              </p>
              <div className="flex justify-between items-center">
                <span className="text-xs">1 year ago</span>
                <div className="flex space-x-1 text-red-500">
                  {[...Array(5)].map((_, i) => (
                    <img
                      key={i}
                      src={starImage}
                      alt="star"
                      className="h-3 w-3"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <button className="bg-transparent border border-red text-white rounded-lg py-2 px-4">
            Show all 213 reviews
          </button>
        </div>
        <hr className="w-full border-[#52443d] my-4" />

        <div className="flex justify-between items-start pt-4">
          <div className="flex items-center space-x-2">
            <img src={flagIcon} alt="report" className="h-4 w-4" />
            <Link to="/report-business" className="underline">
              <span className="underline">Report this company</span>
            </Link>
          </div>
        </div>

        <div className="my-4">
          <button
            className="bg-red text-white py-2 px-4 rounded-lg"
            onClick={handleRequest}
          >
            Request Employment
          </button>
        </div>
      </main>
      {showPaymentModal && (
        <PaymentModal onClose={() => setShowPaymentModal(false)} />
      )}
    </div>
  );
};

export default BusinessOverview;
