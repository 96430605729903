import React from "react";


const InputField = ({ label, type, name, value, onChange, placeholder}) => {
  return (
    <div className="flex flex-col mb-4 w-full md:w-[47%]">
      <label htmlFor={name} className="text-gray-400 mb-1">
        {label}
      </label>
      <input
        id={name}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        className="bg-[#1a120e] text-white px-4 py-3 rounded-md border border-[#52443d] outline-none focus:ring-2"
        onChange={onChange}
      />
    </div>
  );
};

export default InputField;
