import React from "react";
import logo from "../../assets/logonew.svg";
import { Link } from "react-router-dom";

const AuthenticationHeader = ({ hideAuthButtons }) => {
  return (
    <header className="bg-tertiary w-full flex justify-between items-center px-8 md:px-8 py-4">
      <img src={logo} alt="Biva Logo" className="h-8 md:h-10" />
      {! hideAuthButtons && (
        <nav className="flex space-x-6 items-center">
        <Link to="/sign-in" className="text-white text-lg hidden md:block">
          Login
        </Link>
        <Link to="/sign-up" className="text-white text-lg hidden md:block">
        <button className="bg-red text-white rounded-md px-2 py-1 md:px-4 md:py-2 w-24 md:w-36">
          Sign Up
        </button>
        </Link>
      </nav>
      )}
    </header>
  );
};

export default AuthenticationHeader;
