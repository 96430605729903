import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";

const RequestForm = () => {
  const { id: vendorId } = useParams();
  const navigate = useNavigate();
  const [professionalName, setProfessionalName] = useState("");
  const [professionalEmail, setProfessionalEmail] = useState("");
  const [proposal, setProposal] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    const validateForm = () => {
      if (professionalName && professionalEmail && proposal) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    };

    validateForm();
  }, [professionalName, professionalEmail, proposal]);

  const handleSubmit = async () => {
    const authToken = Cookies.get("accessToken");

    if (!authToken) {
      console.error("Authentication token not found in local storage");
      return;
    }

    const formData = new FormData();
    formData.append("business_owner", vendorId); 
    formData.append("name", professionalName);
    formData.append("email", professionalEmail);
    formData.append("proposal", proposal);
    formData.append("status", "pending");
    formData.append("salary", "string"); 

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: formData,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://api.globalbiva.co.ke/api/employment-requests/",
        requestOptions
      );
      const result = await response.json();
      console.log(result);
      if (response.ok) {
        // Handle successful response
        alert("Request submitted successfully!");
        navigate("/professional-home");
      } else {
        console.error("Error submitting request:", result);
        alert("Error submitting request");
      }
    } catch (error) {
      console.error("Error submitting request:", error);
      alert("Error submitting request");
    }
  };

  return (
    <div className="min-h-screen bg-black text-white flex justify-center items-center">
      <div className="bg-[#1a120e] p-8 rounded-lg w-full max-w-lg space-y-8">
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft />
          <span>Back</span>
        </div>

        <div className="space-y-4">
          <h2 className="text-2xl font-semibold">Employment Request Form</h2>

          <div className="bg-[#1a120e] p-4 rounded-md border border-[#52443d] flex items-center">
            <input
              type="text"
              value={professionalName}
              onChange={(e) => setProfessionalName(e.target.value)}
              className="bg-transparent text-white focus:outline-none w-full"
              placeholder="Name"
              required
            />
          </div>

          <div className="bg-[#1a120e] p-4 rounded-md border border-[#52443d] flex items-center">
            <input
              type="email"
              value={professionalEmail}
              onChange={(e) => setProfessionalEmail(e.target.value)}
              className="bg-transparent text-white focus:outline-none w-full"
              placeholder="Email"
              required
            />
          </div>

          <div className="bg-[#1a120e] p-4 rounded-md border border-[#52443d]">
            <textarea
              value={proposal}
              onChange={(e) => setProposal(e.target.value)}
              className="bg-transparent text-white focus:outline-none w-full h-32 resize-none"
              placeholder="Write a proposal"
              required
            ></textarea>
          </div>

          <button
            onClick={handleSubmit}
            className={`bg-red text-white w-full py-3 rounded-md ${
              isButtonDisabled
                ? "cursor-not-allowed opacity-50"
                : "cursor-pointer"
            }`}
            disabled={isButtonDisabled}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestForm;
