import React from "react";
import { Routes, Route } from "react-router-dom";

import HomePage from "../pages/shared/HomePage";
import SignUpPage from "../pages/shared/SignUp";
import Login from "../pages/shared/Login";
import Contact from "../pages/shared/Contact";

const SharedRoutes = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/sign-up" element={<SignUpPage />} />
    <Route path="/sign-in" element={<Login />} />
    <Route path="/contact-form" element={<Contact />} />
  </Routes>
);

export default SharedRoutes;
