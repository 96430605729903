import React, { useState } from "react";
import Cookies from "js-cookie";
import { FaArrowLeft } from "react-icons/fa";
import MpesaLogo from "../../assets/M-PESA-logo.png";
import { ToastContainer } from "react-toastify";

const PaymentModal = ({ onClose }) => {
  const amount = 2000;
  const [phoneNumber, setPhoneNumber] = useState("");

  const PaymentInitiated = async () => {
    const authTokens = Cookies.get("accessToken");

    if (!authTokens) {
      alert("An error occurred. Kindly login again.");
      return;
    }

    const myHeaders = new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${authTokens}`,
    });

    const body = JSON.stringify({
      subscription_type: "PRO_ANNUAL",
      phone_number: phoneNumber,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: body,
    };

    try {
      const response = await fetch(
        "https://api.globalbiva.co.ke/api/initiate-payment/",
        requestOptions
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `HTTP error! Status: ${response.status} - ${errorText}`
        );
      }

      const result = await response.json();
      console.log("Payment Initiation Success:", result);
      alert("Payment initiated successfully, check your mobile device!");
    } catch (error) {
      console.error("Payment initiation failed:", error);
      alert("Payment initiation failed. Please try again later.");
    }

    onClose();
  };

  return (
    <>
      <ToastContainer />
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
          <div className="bg-black text-white p-6 rounded-lg shadow-lg max-w-md w-full relative">
            <div className="flex justify-between items-center mb-4 border-b border-[#a08d85] pb-4">
              <button onClick={onClose}>
                <FaArrowLeft className="h-6 w-6 text-[#f0dfd8]" />
              </button>
              <img src={MpesaLogo} alt="M-Pesa Logo" className="h-12" />
              <div className="h-6 w-6" />
            </div>

            <div className="text-center space-y-8">
              <div>
                <p className="text-sm font-poppins">Annual Subscription</p>
                <div className="bg-[#575757] py-2 px-4 rounded mt-2 inline-block">
                  <span className="text-lg font-semibold">KES. {amount}</span>{" "}
                </div>
              </div>

              <div>
                <p className="text-sm font-poppins">
                  Phone number to complete the payment
                </p>
                <div className="bg-[#575757] py-2 px-4 rounded mt-2">
                  <input
                    type="text"
                    placeholder="254712345678"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="bg-transparent text-white w-full outline-none text-center font-dm-sans"
                  />
                </div>
              </div>

              <div className="flex justify-between items-center mt-8">
                <button
                  className="text-[#e41818] font-poppins font-medium text-sm underline"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  className="bg-[#e41818] text-white py-2 px-8 rounded font-poppins font-medium text-sm"
                  onClick={PaymentInitiated}
                >
                  continue
                </button>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default PaymentModal;
