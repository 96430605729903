import React from "react";

const DeniedRequests = ({ request }) => {
  return (
    <div className="bg-[#1a120e] w-full p-2 rounded-lg border border-[#52443d]  flex flex-row md:flex-col mb-2 md:mb-0">
      <div className=" w-full flex flex-col md:flex-row mb-2 md:mb-0">
        <div className="flex-shrink-0 w-1/3 mr-2 md:w-1/4">
          <img
            src={request.customerProfilePic}
            alt={request.customerName}
            className="h-32 w-24 rounded-lg mb-2 md:mb-0 md:mr-2 object-cover"
          />
        </div>
        <div className="flex-1">
          <div className="flex justify-between items-center mb-1">
            <h1 className="text-[#d7c2b9] text-lg font-normal">
              {request.customerName}
            </h1>
          </div>
          <br />
          <div className="text-[#d7c2b9] text-xs">
            <p className="mb-1 flex justify-between text-base font-light">
              <span>Appointment Date</span>
              <span>{request.booking_date}</span>
            </p>
            <p className="mb-1 flex justify-between text-base font-light">
              <span>Time of Appointment</span>
              <span>{request.booking_time}</span>
            </p>
            <p className="mb-1 flex justify-between text-base font-light">
              <span>Service</span>
              <span>{request.serviceName}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeniedRequests;
