import React, { useEffect, useState } from "react";
import NavBar from "../../components/jobSeekers/NavBar";
import { IoIosArrowForward } from "react-icons/io";
import encryption from "../../assets/encrypted.png";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import { FaCalendarCheck } from "react-icons/fa6";

const Profile = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [isAvailable, setIsAvailable] = useState(false);
  const [expertise, setExpertise] = useState("Beginner");

  const fetchUser = async () => {
    const token = Cookies.get("accessToken");

    if (token) {
      const decoded = jwtDecode(token);
      const userId = decoded.id;

      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://api.globalbiva.co.ke/api/beauty-professionals/${userId}/`,
        requestOptions
      );

      const data = await response.json();
      setUser(data);
      setIsAvailable(data.is_available);
      setExpertise(data.expertise || "Beginner");
    } else {
      alert("An error occurred. Please try again.");
    }
  };

  const handleToggleAvailability = () => {
    setIsAvailable(!isAvailable);
    // Add API call to update availability status on the server if needed
  };

  const handleExpertiseChange = (event) => {
    setExpertise(event.target.value);
    // Add API call to update expertise level on the server if needed
  };

  const logout = () => {
    Cookies.remove("accessToken");
    Cookies.remove("csrftoken");
    navigate("/");
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <div className="min-h-screen bg-black text-white flex flex-col items-center">
      <NavBar />
      {user && (
        <div className="w-full max-w-screen-lg flex flex-col items-start py-12 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col w-full mb-8 space-y-4">
            <h2 className="text-lg font-semibold">Account</h2>
            <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full space-y-8 lg:space-y-0 lg:space-x-5">
              <div className="w-full lg:w-1/3 flex items-center border-b border-[#52443d]">
                <div className="flex items-center">
                  <img
                    src={user.profile_pic}
                    alt="Profile"
                    className="h-8 w-8 m-2"
                  />
                  <div className="flex flex-col items-start justify-center p-4">
                    <p className="font-medium">{user.name}</p>
                    <a href="/professional-profile-details" className="text-sm">
                      Show Profile
                    </a>
                  </div>
                </div>
                <a
                  href="/professional-profile-details"
                  className="h-6 w-6 ml-auto"
                >
                  <IoIosArrowForward />
                </a>
              </div>

              <div className="w-full justify-center lg:w-1/3">
                <h2 className="font-semibold">Availability Status</h2>
                <div className="flex items-center mt-4 space-x-4">
                  <div className="flex items-center space-x-2">
                    <FaCalendarCheck className="h-6 w-6" />
                    <p className="font-medium">Available</p>
                  </div>
                  <div
                    className={`w-12 h-7 flex items-center rounded-full p-1 cursor-pointer ${
                      isAvailable ? "bg-orange-600" : "bg-gray-500"
                    }`}
                    onClick={handleToggleAvailability}
                  >
                    <div
                      className={`bg-orange-950 w-4 h-4 rounded-full shadow-md transform ${
                        isAvailable ? "translate-x-6" : "translate-x-0"
                      }`}
                    ></div>
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-1/3">
                <h2 className="text-lg font-semibold">Level of Expertise</h2>
                <select
                  value={expertise}
                  onChange={handleExpertiseChange}
                  className="text-white bg-black mt-4 p-2 rounded-md w-full"
                >
                  <option value="Beginner">Beginner</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Expert">Expert</option>
                </select>
              </div>
            </div>
          </div>

          <div className="w-full mb-8 space-y-4">
            <h2 className="text-lg font-semibold">Settings</h2>
            <div>
              <h4>Profile status</h4>
              <div className="w-full lg:w-1/4 flex items-center p-4 justify-between">
                <div className="flex items-center space-x-4">
                  <p className="font-medium">Premium</p>{" "}
                  {/* change status here */}
                </div>
                <Link to="/subscription-plan">
                  <IoIosArrowForward className="h-6 w-6" />
                </Link>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row items-start lg:items-center w-full space-y-4 lg:space-y-0 lg:space-x-5">
              <div className="w-full lg:w-1/2 flex items-center p-4 border-b border-[#52443d] justify-between">
                <div className="flex items-center space-x-4">
                  <img
                    src={encryption}
                    alt="Reset Password"
                    className="h-8 w-8"
                  />
                  <p className="font-medium">Reset Password</p>
                </div>
                <Link to="/reset-password">
                  <IoIosArrowForward className="h-6 w-6" />
                </Link>
              </div>
              <div
                className="w-full lg:w-1/2 flex items-center p-4 border-b border-[#52443d] justify-between cursor-pointer"
                onClick={logout}
              >
                <p className="font-medium">Log out</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
