import React, { useState, useRef } from "react";
import verificationIcon from "../../assets/Lock.png";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const OTPVerification = () => {
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const navigate = useNavigate();
  const inputRefs = useRef([]);

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const combinedOtp = otp.join("");

    try {
      const response = await fetch("YOUR_API_ENDPOINT", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ otp: combinedOtp }),
      });

      const data = await response.json();
      console.log("API response:", data);

      if (data.success) {
        console.log("OTP verified successfully!");
      } else {
        console.error("OTP verification failed.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white flex flex-col items-center">
      <main className="max-w-screen-lg w-3/4 flex flex-col items-start py-12 px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl mb-8 text-center">Reset Password</h1>
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft />
          <span>Back</span>
        </div>
        <div className="flex items-center flex-col space-y-4 p-8 w-full max-w-4xl">
          <img
            src={verificationIcon}
            alt="verification-icon"
            className="h-16 w-16 mb-4 mx-auto"
          />
          <h2 className="text-2xl text-white font-bold mb-6 text-center">
            Enter verification code
          </h2>
          <form onSubmit={handleSubmit} className="flex flex-col items-center">
            <div className="flex justify-center mb-4">
              {otp.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  ref={(input) => (inputRefs.current[index] = input)}
                  value={value}
                  onChange={(e) => handleChange(index, e)}
                  className="w-12 h-12 mx-1 text-center text-black text-lg border border-gray-300 rounded focus:outline-none focus:border-red"
                  maxLength={1}
                />
              ))}
            </div>
            <p className="mb-4 text-white">
              Didn't get code <span className="text-red"> Resend code</span>
            </p>
            <div className="flex justify-center">
              <button
                className="bg-red text-white py-2 px-8 rounded-lg"
                type="submit"
                onClick={() => navigate("/set-new-password")}
              >
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
};

export default OTPVerification;
