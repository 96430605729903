import React, { useEffect, useState } from "react";
import AuthenticationHeader from "../../components/shared/AuthenticationHeader";
import iconDropdown from "../../assets/icons-arrow-drop-down-24-px.svg";
import trailingIcon from "../../assets/trailing-icon.svg";
import selectedIcon from "../../assets/selected-icon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import counties from "../../assets/data/counties.json";
const MAX_SERVICES = 15;

const FinishProfessionalSetup = () => {
  const { userID } = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [userType, setUserType] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCounty, setSelectedCounty] = useState("");
  const [subCounty, setSubCounty] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [availableServices, setAvailableServices] = useState([]);
  const [availableSubCounties, setAvailableSubCounties] = useState([]);
  const navigate = useNavigate();

  const maxLength = 500;

  const handleCountyChange = (e) => {
    const selected = e.target.value;
    setSelectedCounty(selected);

    const countySubCounties = counties[selected];
    setAvailableSubCounties(countySubCounties || []);
    setSubCounty(""); 
  };

  const handleDescriptionChange = (e) => {
    if (e.target.value.length <= maxLength) {
      setDescription(e.target.value);
    }
  };

  useEffect(() => {
    const getProfessionalInfo = async () => {
      try {
        const requestOptions = {
          method: "GET",
          redirect: "follow",
        };

        const response = await fetch(
          `https://api.globalbiva.co.ke/api/beauty-professionals/${userID}`,
          requestOptions
        );
        const result = await response.json();

        if (result) {
          console.log(result);
          setEmail(result.email);
          setName(result.name);
          setPhone(result.phone_number);
          setUserType(result.user_type);
        }
      } catch (error) {
        console.error(
          "You took a while, contact customer care or try again",
          error
        );
      }
    };

    getProfessionalInfo();
  }, [userID]);

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const requestOptions = {
          method: "GET",
          redirect: "follow",
        };

        const response = await fetch(
          "https://api.globalbiva.co.ke/api/service-categories/?page=1",
          requestOptions
        );
        const result = await response.json();

        if (result) {
          setMenuItems(result.results);
        }
      } catch (error) {
        console.error("Failed to fetch service categories", error);
      }
    };

    const fetchServices = async () => {
      try {
        const requestOptions = {
          method: "GET",
          redirect: "follow",
        };

        const response = await fetch(
          "https://api.globalbiva.co.ke/api/services/?page=1",
          requestOptions
        );
        const result = await response.json();

        if (result) {
          setAvailableServices(result.results);
        }
      } catch (error) {
        console.error("Failed to fetch services", error);
      }
    };

    fetchMenuItems();
    fetchServices();
  }, []);

  const toggleService = (service) => {
    if (selectedServices.some((s) => s.id === service.id)) {
      setSelectedServices(selectedServices.filter((s) => s.id !== service.id));
    } else if (selectedServices.length < MAX_SERVICES) {
      setSelectedServices([...selectedServices, service]);
    } else {
      alert(`You can select a maximum of ${MAX_SERVICES} services.`);
    }
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    id: "",
    name: "",
  });

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleMenuItemClick = (menuItem) => {
    setSelectedCategory({ id: menuItem.id, name: menuItem.name });
    setShowDropdown(false);
  };

  const handleFinishProfessionalsetup = async (e) => {
    e.preventDefault();
    if (!description) {
      alert("Please enter your bio data to proceed");
      return;
    }
    if (!selectedCounty) {
      alert("Please select your county to proceed");
      return;
    }
    if (!subCounty) {
      alert("Please select your sub-county to proceed");
      return;
    }
    if (!selectedCategory.id) {
      alert("Please select a category to proceed");
      return;
    }
    if (selectedServices.length === 0) {
      alert("Please select at least one service to proceed");
      return;
    }

    try {
      const formdata = new FormData();
      formdata.append("name", name);
      formdata.append("email", email);
      formdata.append("phone_number", phone);
      formdata.append("user_type", userType);
      formdata.append("more.bio", description);
      formdata.append("more.county", selectedCounty);
      formdata.append("more.sub_county", subCounty);
      formdata.append("more.experience_level", "");
      formdata.append("more.availability", "");
      formdata.append("more.employment_status", "");
      formdata.append("more.current_employment", "");
      formdata.append("more.target_employment", "");
      formdata.append("more.working_hours_start", "");
      formdata.append("more.working_hours_end", "");
      formdata.append("service_category", selectedCategory.id);
      selectedServices.forEach((service) => {
        formdata.append("services", service.id);
      });

      const requestOptions = {
        method: "PUT",
        body: formdata,
        redirect: "follow",
      };

      fetch(
        `https://api.globalbiva.co.ke/api/beauty-professionals/${userID}/`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result) {
            toast.success("Professional setup successful");
            console.log("Professional setup successful", result);
            navigate("/sign-in");
          } else {
            alert("Professional setup failed");
          }
        });
    } catch (error) {
      toast.error("Professional setup failed. Please try again later.");
      toast.error("Professional setup failed", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="min-h-screen bg-black flex flex-col items-center">
        <AuthenticationHeader hideAuthButtons={true} />

        <main className="flex flex-col items-center mt-8 px-4 w-full">
          <h1 className="text-white text-center text-xl md:text-2xl xl:text-3xl mb-12">
            Finish Setting Up your Professional Account
          </h1>

          <div className="bg-[#1a120e] rounded-lg p-8 w-full max-w-4xl">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
              <div className="bg-[#1a120e] border border-[#52443d] rounded-lg p-4">
                <select
                  value={selectedCounty}
                  onChange={handleCountyChange}
                  className="bg-[#1a120e] text-white w-full outline-none"
                >
                  <option value="" disabled>
                    Select County
                  </option>
                  {Object.keys(counties).map((county, index) => (
                    <option key={index} value={county}>
                      {county}
                    </option>
                  ))}
                </select>
              </div>
              <div className="bg-[#1a120e] border border-[#52443d] rounded-lg p-4">
                <select
                  value={subCounty}
                  onChange={(e) => setSubCounty(e.target.value)}
                  className="bg-[#1a120e] text-white w-full outline-none"
                  disabled={!availableSubCounties.length}
                >
                  <option value="" disabled>
                    Select Sub-County
                  </option>
                  {availableSubCounties.map((subCounty, index) => (
                    <option key={index} value={subCounty}>
                      {subCounty}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
              {/* description */}
              <div className="relative flex-1 bg-[#1a120e] border border-[#52443d] rounded-lg overflow-hidden p-4">
                <textarea
                  placeholder="Description"
                  value={description}
                  maxLength={maxLength}
                  className="bg-transparent text-[#f0dfd8] w-full outline-none break-words resize-none h-full overflow-hidden"
                  onChange={handleDescriptionChange}
                />
                <div className="absolute bottom-2 right-2 text-sm text-gray-400">
                  {description.length}/{maxLength}
                </div>
              </div>
              {/* Category and services */}
              <div className="pb-1 ">
                {/* Category */}
                <div className="relative bg-[#1a120e] border border-[#52443d] rounded-lg p-4 mb-2">
                  <input
                    type="text"
                    placeholder="Category"
                    value={selectedCategory.name}
                    onChange={() => {}}
                    className="bg-transparent text-white w-full outline-none"
                    onClick={handleDropdownToggle}
                  />
                  <img
                    src={iconDropdown}
                    alt="dropdown"
                    className="h-6 absolute right-4 top-1/2 transform -translate-y-1/2"
                    onClick={handleDropdownToggle}
                  />
                  {showDropdown && (
                    <div className="absolute left-0 w-full mt-2 bg-[#1a120e] border border-[#52443d] rounded-lg max-h-40 overflow-y-auto z-10">
                      {menuItems.map((item, index) => (
                        <div
                          key={index}
                          className="text-white cursor-pointer px-4 py-2 hover:bg-[#52443d]"
                          onClick={() => handleMenuItemClick(item)}
                        >
                          {item.name}{" "}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {/* Services */}
                <div className="flex-1 bg-[#1a120e] border border-[#52443d] rounded-lg p-4 flex flex-col gap-3">
                  <label className="text-[#e8def8] text-sm mb-2">
                    Select Services
                  </label>
                  {/* Selected services */}
                  <div className="flex flex-wrap gap-2">
                    {selectedServices.map((service, index) => (
                      <div
                        key={index}
                        className="bg-[#4a4458] rounded-lg px-2 py-1 cursor-pointer"
                        onClick={() => toggleService(service)}
                      >
                        <span className="text-[#e8def8] text-sm">
                          {service.name}
                        </span>
                        <img
                          src={selectedIcon}
                          alt="selected"
                          className="h-4 ml-1"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-end flex-1 justify-start flex-wrap p-1">
              {availableServices.map((service, index) => (
                <div
                  key={index}
                  className="flex items-center flex-wrap m-1 bg-[#4a4458] rounded-lg px-2 py-1 cursor-pointer"
                  onClick={() => toggleService(service)}
                >
                  <span className="text-[#e8def8] text-sm">{service.name}</span>
                  <img
                    src={
                      selectedServices.some((s) => s.id === service.id)
                        ? selectedIcon
                        : trailingIcon
                    }
                    alt="trailing"
                    className="h-4 ml-1"
                  />
                </div>
              ))}
            </div>
            <div className="flex justify-center">
              <button
                className="bg-red text-white py-2 px-8 rounded-lg"
                onClick={handleFinishProfessionalsetup}
              >
                That's It
              </button>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default FinishProfessionalSetup;
