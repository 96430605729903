import React, { useEffect, useState } from "react";
import NavBar from "../../components/customers/NavBar";
import { useNavigate } from "react-router-dom";
import CardProfessional from "../../components/customers/CardProfessional";
import CardBusiness from "../../components/customers/CardBusiness";
import SearchBar from "../../components/customers/SearchBar";

const Home = () => {
  const navigate = useNavigate();
  const [beautyProfessionals, setBeautyProfessionals] = useState([]);
  const [businessOwners, setBusinessOwners] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const handleProfessionalCardClick = (user) => {
    navigate(`/ProfessionalOverview/${user.id}`);
  };

  const handleBusinessCardClick = (user) => {
    navigate(`/BusinessOverview/${user.id}`);
  };


  const fetchData = async () => {
    try {
      setLoading(true);
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const fetchBeautyProfessionals = fetch(
        "https://api.globalbiva.co.ke/api/beauty-professionals/",
        requestOptions
      ).then((response) => response.json());

      const fetchBusinesses = fetch(
        "https://api.globalbiva.co.ke/api/business-owners/",
        requestOptions
      ).then((response) => response.json());

      const [beautyProfessionalsData, businessesData] = await Promise.all([
        fetchBeautyProfessionals,
        fetchBusinesses,
      ]);

      setBeautyProfessionals(beautyProfessionalsData.results || []);
      setBusinessOwners(businessesData.results || []);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  

  return (
    <div className="bg-black text-white min-h-screen flex flex-col items-center">
      <NavBar />
      <main className="w-full flex items-center flex-col  max-w-screen-lg px-4 sm:px-6 lg:px-8 mt-8">
        <SearchBar />

        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {/* <h2 className="text-2xl font-bold mb-4">Beauty Professionals</h2> */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 w-full mb-8">
              {beautyProfessionals.length > 0 ? (
                beautyProfessionals.map((professional, index) => (
                  <CardProfessional
                    key={index}
                    user={professional}
                    handleCardClick={handleProfessionalCardClick}
                  />
                ))
              ) : (
                <p>No beauty professionals found.</p>
              )}
            </div>

            {/* <h2 className="text-2xl font-bold mb-4">Businesses</h2> */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 w-full">
              {businessOwners.length > 0 ? (
                businessOwners.map((business, index) => (
                  <CardBusiness
                    key={index}
                    user={business}
                    handleCardClick={handleBusinessCardClick}
                  />
                ))
              ) : (
                <p>No businesses found.</p>
              )}
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default Home;
