import React, { useState, useEffect } from "react";
import NavBar from "../../components/businessOwner/NavBar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CardProfessional from "../../components/businessOwner/CardProfessional";
import SearchBar from "../../components/businessOwner/SearchBar";
import SkeletonCard from "../../components/shared/Skeleton";

const Home = () => {
  const navigate = useNavigate();
  const handleCardClick = (user) => {
    navigate(`/OverviewPage/${user.id}`);
  };
  const [loading, setLoading] = useState(true);
  const [beautyProfessionals, setBeautyProfessionals] = useState([]);


  const fetchData = async () => {
    try {
      setLoading(true);
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://api.globalbiva.co.ke/api/beauty-professionals/",
        requestOptions
      );

      const result = await response.json();
      setBeautyProfessionals(result.results || []);
    } catch (error) {
      console.log(error);
      toast.error("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="bg-black text-white min-h-screen flex flex-col items-center">
      <NavBar />
      <main className="w-full max-w-screen-lg px-4 sm:px-6 lg:px-8 mt-8">
        <div className="flex justify-center items-center">
          <SearchBar />
        </div>
        {loading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
              {beautyProfessionals.length > 0 ? (
                beautyProfessionals.map((professional, index) => (
                  <CardProfessional
                    key={index}
                    user={professional}
                    handleCardClick={handleCardClick}
                  />
                ))
              ) : (
                <p>No beauty professionals found.</p>
              )}
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default Home;
