import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import NavBar from "../../components/customers/NavBar";
import SelectButton from "../../components/shared/SelectButton";
import PendingAppointments from "../../components/customers/PendingAppointments";
import ApprovedAppointments from "../../components/customers/ApprovedAppointments";
import CancelledAppointments from "../../components/customers/CancelledAppointments";

function Appointments() {
  const [selected, setSelected] = useState("Pending");
  const [appointments, setAppointments] = useState([]);
  const [approvedAppointments, setApprovedAppointments] = useState([]);
  const [pendingAppointments, setPendingAppointments] = useState([]);
  const [cancelledAppointments, setCancelledAppointments] = useState([]);

  const fetchVendor = async (vendorID) => {
    try {
      const response = await fetch(
        `https://api.globalbiva.co.ke/api/users/${vendorID}`,
        {
          method: "GET",
          redirect: "follow",
        }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("An error occurred fetching vendor data:", error);
      return null;
    }
  };

  const fetchService = async (serviceID) => {
    try {
      const response = await fetch(
        `https://api.globalbiva.co.ke/api/services/${serviceID}`,
        {
          method: "GET",
          redirect: "follow",
        }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("An error occurred fetching service data:", error);
      return null;
    }
  };

  const fetchVendorAndServiceDetailsForAppointments = async (appointments) => {
    const updatedAppointments = await Promise.all(
      appointments.map(async (appointment) => {
        const vendorData = await fetchVendor(appointment.vendor);
        const serviceData = await fetchService(appointment.service);
        return {
          ...appointment,
          vendorName: vendorData?.name || "Unknown",
          vendorUsertype: vendorData?.user_type || "Unknown",
          vendorProfilePic: vendorData?.profile_pic || "",
          serviceName: serviceData?.name || "Unknown",
        };
      })
    );
    setAppointments(updatedAppointments);
    categorizeAppointments(updatedAppointments);
  };

  const categorizeAppointments = (appointments) => {
    const approved = appointments.filter(
      (appointment) => appointment.status === "confirmed"
    );
    const pending = appointments.filter(
      (appointment) => appointment.status === "pending"
    );
    const cancelled = appointments.filter(
      (appointment) => appointment.status === "cancelled"
    );

    setApprovedAppointments(approved);
    setPendingAppointments(pending);
    setCancelledAppointments(cancelled);
  };

  useEffect(() => {
    const fetchAppointments = async () => {
      const authToken = Cookies.get("accessToken");

      if (!authToken) {
        alert("An error occurred. Please try again.");
        return;
      }

      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${authToken}`);

        const response = await fetch(
          "https://api.globalbiva.co.ke/api/user-bookings/",
          {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
          }
        );

        const data = await response.json();
        const appointments = data.results;

        if (appointments.length > 0) {
          await fetchVendorAndServiceDetailsForAppointments(appointments);
        } else {
          setAppointments(appointments);
          categorizeAppointments(appointments); 
        }
      } catch (error) {
        console.error("An error occurred fetching appointments:", error);
      }
    };

    fetchAppointments();
  }, []);

  useEffect(() => {
    const filtered = appointments.filter(
      (appointment) =>
        (selected === "Pending" && appointment.status === "pending") ||
        (selected === "Approved" && appointment.status === "confirmed") ||
        (selected === "Denied" && appointment.status === "cancelled")
    );
    categorizeAppointments(filtered);
  }, [selected, appointments]);

  return (
    <div className="bg-black text-white min-h-screen flex flex-col items-center">
      <NavBar />
      <div className="w-full flex items-center flex-col max-w-screen-lg px-4 sm:px-6 lg:px-8 mt-8">
        <SelectButton
          texts={["Approved", "Pending", "Denied"]}
          selected={selected}
          setSelected={setSelected}
        />

        <main className="flex w-full items-start flex-col max-w-screen-lg px-4 sm:px-6 lg:px-8 mt-8 overflow-x-hidden">
          {selected === "Approved" && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 w-full">
              {approvedAppointments.map((appointment) => (
                <ApprovedAppointments
                  key={appointment.id}
                  appointment={appointment}
                />
              ))}
            </div>
          )}

          {selected === "Pending" && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 w-full">
              {pendingAppointments.map((appointment) => (
                <PendingAppointments
                  key={appointment.id}
                  appointment={appointment}
                />
              ))}
            </div>
          )}

          {selected === "Denied" && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 w-full">
              {cancelledAppointments.map((appointment) => (
                <CancelledAppointments
                  key={appointment.id}
                  appointment={appointment}
                />
              ))}
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

export default Appointments;
