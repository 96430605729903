import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaStar } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

const EmployerRatingModal = ({ onClose }) => {
  const { id: employeeId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [rating, setRating] = useState(0); 
  const [hoverRating, setHoverRating] = useState(0);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const requestOptions = {
          method: "GET",
          redirect: "follow",
        };

        const response = await fetch(
          `https://api.globalbiva.co.ke/api/users/${employeeId}/`,
          requestOptions
        );
        const data = await response.json();
        setUser(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUser();
  }, [employeeId]);

  if (!user) {
    return <div>Loading...</div>;
  }

  const handleSubmitReview = () => {
    alert(`Review received with a rating of ${rating} stars!`);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
      <div className="bg-[#1a120e] text-white p-6 rounded-lg shadow-lg max-w-lg w-full">
        <div className="flex justify-between items-center mb-6">
          <button onClick={() => navigate(-1)}>
            <FaArrowLeft className="h-6 w-6 text-[#f0dfd8]" />
          </button>
          <h2 className="text-lg font-poppins font-medium">
            How would you rate this Employer
          </h2>
          <div className="h-6 w-6" />
        </div>

        <div className="mb-8 p-4 bg-[#1a120e] rounded-lg border border-[#52443d]">
          <div className="flex space-x-4">
            <img
              src={user.profile_pic}
              alt="Employer"
              className="h-24 w-20 object-cover rounded-lg"
            />
            <div className="text-left">
              <p>
                <span className="font-semibold">{user.name}</span>
              </p>
              <p className="text-sm text-[#d7c2b9]">Nairobi, Expert</p>
              <p className="text-sm text-[#d7c2b9]">
                Joined Company: 24/04/2024
              </p>
              <div className="mt-2 flex flex-wrap text-sm text-[#d7c2b9]">
                <span>Manicure</span>
                <span className="ml-3">Pedicure</span>
                <span className="ml-3">Pedicure</span>
              </div>
            </div>
          </div>
        </div>

        <div className="my-4">
          <h3 className="text-lg font-poppins">Ratings</h3>
          <div className="flex space-x-4 my-2">
            {Array(5)
              .fill("")
              .map((_, index) => (
                <FaStar
                  key={index}
                  className={`text-2xl ${
                    (hoverRating || rating) > index
                      ? "text-yellow-500"
                      : "text-gray-500"
                  }`}
                  onClick={() => setRating(index + 1)}
                  onMouseEnter={() => setHoverRating(index + 1)}
                  onMouseLeave={() => setHoverRating(0)}
                />
              ))}
          </div>
        </div>

        <div className="my-4">
          <label className="text-sm font-poppins block mb-2">
            Reviewer's Name
          </label>
          <input
            type="text"
            className="w-full p-2 rounded bg-[#1a120e] border border-[#52443d] outline-none"
          />
        </div>

        <div className="my-4">
          <label className="text-sm font-poppins block mb-2">
            Write a Review
          </label>
          <textarea
            className="w-full p-2 rounded bg-[#1a120e] border border-[#52443d] outline-none"
            rows="5"
          ></textarea>
        </div>

        <div className="text-center">
          <button
            className="bg-[#e41818] text-white py-2 px-8 rounded font-poppins font-medium text-sm"
            onClick={handleSubmitReview}
            disabled={rating === 0} // Disable the button if no rating is selected
          >
            Submit Review
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmployerRatingModal;
