import React, { useState } from "react";
import {
  FaCheck,
} from "react-icons/fa";
import NavBar from "../../components/jobSeekers/NavBar";
import PaymentModal from "../../components/shared/PaymentModal";

const SubscriptionPlan = () => {
    const [showModal, setShowModal] = useState(false);


  return (
    <div className="min-h-screen bg-black text-white flex flex-col items-center">
      <NavBar />
      <div className="w-full flex items-center flex-col max-w-screen-lg px-4 sm:px-6 lg:px-8 mt-8">
        {showModal && <PaymentModal onClose={() => setShowModal(false)} />}
        <div className="flex  flex-wrap mt-6 p-2 sm:justify-start bg-[#141218] w-fit items-center rounded-md">
          <h1 className="text-4xl font-bold text-center text-white ">
            Subscription Plan
          </h1>
        </div>

        <div className="flex flex-col md:flex-row justify-center items-center space-y-6 md:space-y-0 md:space-x-12 py-12">
          {/* Free Plan */}
          <div className="bg-[#1A120E] p-8 rounded-xl shadow-lg w-80 text-white text-center space-y-6">
            <h2 className="text-3xl text-start font-bold">Free</h2>
            <ul className="text-left space-y-4">
              <li className="flex items-center space-x-2">
                <FaCheck />
                <span>Browse Businesses based on Location</span>
              </li>
              <li className="flex items-center space-x-2">
                <FaCheck />
                <span>See Business services and reviews</span>
              </li>
              <li className="flex items-center space-x-2">
                <FaCheck />
                <span>Get One time contact Information</span>
              </li>
            </ul>
            <button className="bg-[#3d3533] text-white font-bold py-2 px-4 rounded cursor-not-allowed opacity-50">
              Already using
            </button>
          </div>

          {/* Premium Plan */}
          <div className="bg-[#1A120E] p-8 rounded-xl shadow-lg w-80 text-white text-center space-y-6">
            <h2 className="text-3xl text-start font-bold">Premium</h2>
            <div>
              <span className="text-7xl text-start font-bold">2000</span>
              <span className="text-2xl">/year</span>
            </div>
            <ul className="text-left space-y-4">
              <li className="flex items-center space-x-2">
                <FaCheck />
                <span>Browse Businesses based on Location</span>
              </li>
              <li className="flex items-center space-x-2">
                <FaCheck />
                <span>Contact Businesses directly</span>
              </li>
              <li className="flex items-center space-x-2">
                <FaCheck />
                <span>Upload verification documents</span>
              </li>
            </ul>
            <button className="bg-red text-white font-bold py-2 px-4 rounded hover:bg-[#d62839]" onClick={() => setShowModal(true)}>
              Subscribe
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlan;
