import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import NavBar from "../../components/jobSeekers/NavBar";
import EmployeeCard from "../../components/businessOwner/EmployeeCard";

function Employer() {
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      const authToken = Cookies.get("accessToken");

      if (!authToken) {
        alert("An error occurred. Kindly login again.");
        return;
      }

      try {
        const requestOptions = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          redirect: "follow",
        };
        const response = await fetch(
          "https://api.globalbiva.co.ke/api/business-employees/",
          requestOptions
        );

        const data = await response.json();
        console.log("API Response:", data);
        const result = data.results;
        const employeeIds = result.map((item) => item.employee);
        console.log("Employee IDs:", employeeIds);

        setEmployees(employeeIds || []);
      } catch (error) {
        console.error("An error occurred fetching employees:", error);
      }
    };

    fetchEmployees();
  }, []);

  return (
    <div className="bg-black text-white min-h-screen flex flex-col items-center">
      <NavBar />
      <div className="w-full flex items-center flex-col max-w-screen-lg px-4 sm:px-6 lg:px-8 mt-8">
        <main className="flex w-full items-start flex-col max-w-screen-lg px-4 sm:px-6 lg:px-8 mt-8 overflow-x-hidden">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 w-full">
            {employees.map((employeeId) => (
              <EmployeeCard key={employeeId} employeeId={employeeId} />
            ))}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Employer;
