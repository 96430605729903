import React from "react";
import { Link } from "react-router-dom";
import Button from "./Button";

export default function OnboardingHeader({ logo }) {
  return (
    <header className="bg-purple w-full py-4 flex justify-between items-center px-4 md:px-8">
      <div>
        <img src={logo} alt="Biva Logo" className="h-8 md:h-10" />
      </div>
      <nav className="hidden md:block">
        {/* <ul className="flex space-x-4 md:space-x-8">
          <li>
            <Link to="/" className="text-white text-sm md:text-base">
              About Us
            </Link>
          </li>
          <li>
            <Link to="/" className="text-white text-sm md:text-base">
              Testimonials
            </Link>
          </li>
        </ul> */}
      </nav>
      <div className="flex space-x-4 items-center">
        <Link to="/sign-in" className="text-white text-sm md:text-base">
          Login
        </Link>
        <Link to="/sign-up" className="text-white text-sm md:text-base">
        <Button
          text="Sign Up"
          className="bg-black text-white rounded-md px-2 py-1 md:px-4 md:py-2 w-24 md:w-36"
        />
        </Link>
      </div>
    </header>
  );
}
