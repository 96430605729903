import React from "react";
import AuthenticationHeader from "../../components/shared/AuthenticationHeader";

const Contact = () => {
  return (
    <div className="min-h-screen w-full bg-black text-white flex flex-col items-center">
      <AuthenticationHeader />
      <main className="max-w-screen-lg w-full flex flex-col items-center py-12 px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl mb-8 text-center">
          Get in touch for Assistance
        </h1>

        <div className="bg-[#1a120e] p-8 rounded-md flex justify-center w-full max-w-4xl">
          <form className="space-y-6 w-full max-w-md">
            <div>
              <label
                className="block text-sm font-medium mb-2"
                htmlFor="fullName"
              >
                Full Name
              </label>
              <input
                id="fullName"
                name="fullName"
                type="text"
                className="block w-full bg-[#1a120e] border border-[#52443d] rounded-md p-2.5"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2" htmlFor="email">
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                className="block w-full bg-[#1a120e] border border-[#52443d] rounded-md p-2.5"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2" htmlFor="phone">
                Phone
              </label>
              <input
                id="phone"
                name="phone"
                type="tel"
                className="block w-full bg-[#1a120e] border border-[#52443d] rounded-md p-2.5"
              />
            </div>

            <div>
              <label
                className="block text-sm font-medium mb-2"
                htmlFor="business"
              >
                Business Name (If applicable)
              </label>
              <input
                id="business"
                name="business"
                type="text"
                className="block w-full bg-[#1a120e] border border-[#52443d] rounded-md p-2.5"
              />
            </div>

            <div>
              <label
                className="block text-sm font-medium mb-2"
                htmlFor="message"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                className="block w-full bg-[#1a120e] border border-[#52443d] rounded-md p-2.5 h-40 resize-none"
              ></textarea>
            </div>

            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-red text-white font-semibold py-2 px-6 rounded-md"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
};

export default Contact;
