import React from "react";
import { Routes, Route } from "react-router-dom";
import FinishProfessionalSetup from "../pages/jobSeekers/FinishProfessionalSetup";
import Home from "../pages/jobSeekers/Home";
import BusinessOverview from "../pages/jobSeekers/BusinessOverview";
import Profile from "../pages/jobSeekers/Profile";
import Employer from "../pages/jobSeekers/Employer";
import Requests from "../pages/jobSeekers/Requests";
import RequestForm from "../pages/jobSeekers/RequestForm";
import ReportBusiness from "../pages/jobSeekers/ReportBusiness";
import ProfileDetails from "../pages/jobSeekers/ProfileDetails";
import SubscriptionPlan from "../pages/jobSeekers/SubscriptionPlan";
import EmployerRatingModal from "../pages/jobSeekers/LeaveAReview";

const ProfessionalRoutes = () => (
  <Routes>
    <Route
      path="/finish-setup-business-professional/:userID"
      element={<FinishProfessionalSetup />}
    />
    <Route path="/professional-home" element={<Home />} />
    <Route path="/BusinessOverviewPage/:id" element={<BusinessOverview />} />
    <Route path="/professional-profile" element={<Profile />} />
    <Route path="/professional-profile-details" element={<ProfileDetails />} />
    <Route path="/professional-employer" element={<Employer />} />
    <Route path="/professional-requests" element={<Requests />} />
    <Route path="/subscription-plan" element={<SubscriptionPlan />} />
    <Route path="/employment-requests/:id" element={<RequestForm />} />
    <Route path="/leave-a-review/:id" element={<EmployerRatingModal />} />
    <Route path="/report-business" element={<ReportBusiness />} />
  </Routes>
);

export default ProfessionalRoutes;
