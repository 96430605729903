import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logonew.svg";
import { FaBars, FaTimes } from "react-icons/fa";
import { IoMdNotificationsOutline } from "react-icons/io";
import Cookies from "js-cookie";
import { MdOutlineAccountCircle } from "react-icons/md";
import NotificationComponent from "../shared/Notification";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const [notifications, setNotifications] = useState([]);

    const token = Cookies.get("accessToken");
    console.log(token);
    useEffect(() => {
      const fetchNotifications = async () => {
        try {
          const response = await fetch(
            "https://api.globalbiva.co.ke/api/notifications/",
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          console.log(response);
          if (!response.ok) {
            throw new Error("Failed to fetch notifications");
          }

          const data = await response.json();
          setNotifications(data.results || []);
        } catch (error) {
          console.error("Error fetching notifications:", error);
        }
      };

      fetchNotifications();
    }, [token]);

    const markAllAsRead = async () => {
      try {
        const response = await fetch(
          "https://api.globalbiva.co.ke/api/notifications/mark-all-as-read/",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          // Update the state to mark all notifications as read
          setNotifications((prevNotifications) =>
            prevNotifications.map((notification) => ({
              ...notification,
              read: true,
            }))
          );
        } else {
          throw new Error("Failed to mark all notifications as read");
        }
      } catch (error) {
        console.error("Error marking all notifications as read:", error);
      }
    };

    const markAsRead = async (id) => {
      try {
        const response = await fetch(
          `https://api.globalbiva.co.ke/api/notifications/${id}/mark-as-read/`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          // Update the state to mark this specific notification as read
          setNotifications((prevNotifications) =>
            prevNotifications.map((notification) =>
              notification.id === id
                ? { ...notification, read: true }
                : notification
            )
          );
        } else {
          throw new Error(`Failed to mark notification ${id} as read`);
        }
      } catch (error) {
        console.error(`Error marking notification ${id} as read:`, error);
      }
    };

    const clearNotifications = () => {
      setNotifications([]);
      setShowNotifications(false);
    };

    const unreadCount = notifications.filter(
      (notification) => !notification.read
    ).length;


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="w-full bg-[#1a120e]">
      <div className="flex justify-between items-center max-w-7xl mx-auto py-4 px-4">
        <div>
          <img src={logo} alt="logo" className="h-8 md:h-10" />
        </div>

        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none"
          >
            {isMenuOpen ? (
              <FaTimes className="h-6 w-6" />
            ) : (
              <FaBars className="h-6 w-6" />
            )}
          </button>
        </div>

        <div
          className={`flex-col md:flex-row ${
            isMenuOpen ? "flex" : "hidden"
          } md:flex md:items-center space-y-4 md:space-y-0 md:space-x-8 absolute md:static top-16 left-0 w-full md:w-auto bg-[#1a120e] md:bg-transparent px-4 md:px-0 py-2 md:py-0`}
        >
          <Link
            to="/customer-home"
            className="text-base font-medium text-[#f0dfd8]"
          >
            Home
          </Link>
          <Link
            to="/appointments"
            className="text-base font-medium text-[#f0dfd8]"
          >
            Appointments
          </Link>
          <Link
            to="/appointments-summary"
            className="text-base font-medium text-[#f0dfd8]"
          >
            Summary
          </Link>
        </div>

        <div className="hidden md:flex items-center space-x-8">
          <div className="relative">
            <IoMdNotificationsOutline
              alt="notifications"
              className="h-6 w-6 cursor-pointer"
              onClick={() => setShowNotifications(true)}
            />
            {unreadCount > 0 && (
              <span className="absolute -top-3 bg-rose-500 -right-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                {unreadCount}
              </span>
            )}
          </div>

          <Link to="/customer-profile">
            <MdOutlineAccountCircle alt="account" className="h-6 w-6" />
          </Link>
        </div>
      </div>

      {isMenuOpen && (
        <div className="flex md:hidden justify-center space-x-8 py-2">
          <IoMdNotificationsOutline
            className="h-6 w-6"
            onClick={() => setShowNotifications(true)}
          />
          <Link to="/customer-profile">
            <MdOutlineAccountCircle className="h-6 w-6" />
          </Link>
        </div>
      )}
      {showNotifications && (
        <NotificationComponent
          notifications={notifications}
          onClose={() => setShowNotifications(false)}
          markAllAsRead={markAllAsRead}
          clearNotifications={clearNotifications}
          markAsRead={markAsRead} 
        />
      )}
    </div>
  );
};

export default NavBar;
