import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import NavBar from "../../components/jobSeekers/NavBar";
import SelectButton from "../../components/shared/SelectButton";
import { jwtDecode } from "jwt-decode";
import PendingRequests from "../../components/jobSeekers/PendingRequests";
import ApprovedRequests from "../../components/jobSeekers/ApprovedRequests";
import DeniedRequests from "../../components/jobSeekers/DeniedRequests";

function Requests() {
  const [selected, setSelected] = useState("Pending");
  const [requests, setRequests] = useState([]);
  const [approvedRequests, setApprovedRequests] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [deniedRequests, setDeniedRequests] = useState([]);

  const fetchCustomer = async (customerID) => {
    try {
      const response = await fetch(
        `https://api.globalbiva.co.ke/api/users/${customerID}`,
        {
          method: "GET",
          redirect: "follow",
        }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("An error occurred fetching customer data:", error);
      return null;
    }
  };

  const fetchService = async (serviceID) => {
    try {
      const response = await fetch(
        `https://api.globalbiva.co.ke/api/services/${serviceID}`,
        {
          method: "GET",
          redirect: "follow",
        }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("An error occurred fetching service data:", error);
      return null;
    }
  };

  const fetchCustomerAndServiceDetails = async (requests) => {
    const updatedRequests = await Promise.all(
      requests.map(async (request) => {
        const customerData = await fetchCustomer(request.customer);
        const serviceData = await fetchService(request.service);
        return {
          ...request,
          customerName: customerData?.name || "Unknown",
          customerEmail: customerData?.email || "Unknown",
          customerProfilePic: customerData?.profile_pic || "",
          serviceName: serviceData?.name || "Unknown",
        };
      })
    );
    setRequests(updatedRequests);
    categorizeRequests(updatedRequests);
  };

  const categorizeRequests = (requests) => {
    const approved = requests?.filter((request) => request.status === "confirmed");
    const pending = requests?.filter((request) => request.status === "pending");
    const denied = requests?.filter((request) => request.status === "cancelled");

    console.log("Approved Requests:", approved);
    console.log("Pending Requests:", pending);
    console.log("Denied Requests:", denied);

    setApprovedRequests(approved);
    setPendingRequests(pending);
    setDeniedRequests(denied);
  };

  useEffect(() => {
    const fetchRequests = async () => {
      const authToken = Cookies.get("accessToken");

      if (!authToken) {
        alert("An error occurred. Kindly login again.");
        return;
      }

      const user = jwtDecode(authToken);
      const professionalID = user.id;

      try {
        const requestOptions = {
          method: "GET",
          redirect: "follow",
        };
        const response = await fetch(
          `https://api.globalbiva.co.ke/api/bookings/professional-bookings/?professional_id=${professionalID}`,
          requestOptions
        );

        const data = await response.json();
        if (data.length > 0) {
          await fetchCustomerAndServiceDetails(data);
        } else {
          setRequests(data);
          categorizeRequests(data);
        }
      } catch (error) {
        console.error("An error occurred fetching requests:", error);
      }
    };

    fetchRequests();
  }, []);

  useEffect(() => {
    const filtered = requests.filter(
      (request) =>
        (selected === "Pending" && request.status === "pending") ||
        (selected === "Approved" && request.status === "confirmed") ||
        (selected === "Denied" && request.status === "cancelled")
    );
    categorizeRequests(filtered);
  }, [selected, requests]);

  return (
    <div className="bg-black text-white min-h-screen flex flex-col items-center">
      <NavBar />
      <div className="w-full flex items-center flex-col max-w-screen-lg px-4 sm:px-6 lg:px-8 mt-8">
        <SelectButton
          texts={["Approved", "Pending", "Denied"]}
          selected={selected}
          setSelected={setSelected}
        />

        <main className="flex w-full items-start flex-col max-w-screen-lg px-4 sm:px-6 lg:px-8 mt-8 overflow-x-hidden">
          {selected === "Approved" ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 w-full">
              {approvedRequests.map((request) => (
                <ApprovedRequests key={request.id} request={request} />
              ))}
            </div>
          ) : null}

          {selected === "Pending" && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 w-full">
              {pendingRequests.map((request) => (
                <PendingRequests key={request.id} request={request} />
              ))}
            </div>
          )}

          {selected === "Denied" && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 w-full">
              {deniedRequests.map((request) => (
                <DeniedRequests key={request.id} request={request} />
              ))}
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

export default Requests;
