import React from "react";
import starIcon from "../../assets/icons-star.svg";
import { MdVerified } from "react-icons/md";

const CardBusiness = ({ user, handleCardClick }) => {
  const formatRating = (rating) => {
    return Number.isInteger(rating) ? rating : rating.toFixed(1);
  };

  return (
    <div
      className="bg-[#1a120e] w-full p-2 rounded-lg border border-[#52443d] flex flex-col md:flex-row mb-2 md:mb-0"
      onClick={() => handleCardClick(user)}
    >
      <div className="flex-shrink-0 w-1/3 md:w-1/4">
        <img
          src={user.profile_pic}
          alt={user.more.business_name}
          className="h-32 w-24 rounded-lg mb-2 md:mb-0 md:mr-2 object-cover"
        />
      </div>
      <div className="flex-1">
        <div className="flex justify-between items-center mb-1 cursor-pointer">
          <h1 className="text-[#d7c2b9] text-lg font-bold flex items-center">
            {user.more.business_name}
            {user.email_verified && ( // Display the verified icon if the user is verified
              <MdVerified color="red" alt="verified" className="h-4 w-4 ml-2" />
            )}
          </h1>
          <div className="flex items-center">
            <img src={starIcon} alt="star" className="h-3 w-3 mr-1" />
            <span className="text-[#d7c2b9] text-xs">
              {formatRating(user.more.rating)}
            </span>
          </div>
        </div>
        <div className="text-[#d7c2b9] text-xs">
          {user.more.availability && (
            <p className="mb-2 flex justify-between">
              <span className="font-semibold">Availability:</span>
              <span className="ml-1">{user.more.availability}</span>
            </p>
          )}
          <p className="mb-1 flex justify-between text-base font-semibold">
            <span>
              {user.more.sub_county && (
                <span className="mr-1">{user.more.sub_county},</span>
              )}
              {user.more.county && (
                <span className="mr-1"> {user.more.county}</span>
              )}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardBusiness;
