import React from "react";

const SkeletonCard = () => {
  return (
    <div className="animate-pulse flex flex-col items-center bg-[#2e2b27] p-4 rounded-lg">
      <div className="bg-gray-700 h-24 w-24 rounded-full mb-4"></div>
      <div className="h-4 bg-gray-700 w-3/4 rounded mb-2"></div>
      <div className="h-4 bg-gray-700 w-1/2 rounded"></div>
    </div>
  );
};

export default SkeletonCard;
