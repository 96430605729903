import React from "react";

const PendingAppointments = ({ appointment }) => {
  const handleCancel = async () => {
    console.log("Cancelling appointment...");
    const formdata = new FormData();
    formdata.append("status", "cancelled");

    console.log(appointment.id);
    const requestOptions = {
      method: "PATCH",
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `https://api.globalbiva.co.ke/api/user-bookings/${appointment.id}/`,
        requestOptions
      );

      console.log(response);
      if (response.ok) {
        console.log("Appointment cancelled successfully!");
      } else {
        console.error("Failed to cancel appointment:", response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div className="bg-[#1a120e] w-full p-2 rounded-lg border border-[#52443d]  flex flex-row md:flex-col mb-2 md:mb-0">
      <div className=" w-full flex flex-col md:flex-row mb-2 md:mb-0">
        <div className="flex-shrink-0 w-1/3 mr-2 md:w-1/4">
          <img
            src={appointment.vendorProfilePic}
            alt={appointment.vendorName}
            className="h-32 w-24 rounded-lg mb-2 md:mb-0 md:mr-2 object-cover"
          />
        </div>
        <div className="flex-1">
          <div className="flex justify-between items-center mb-1">
            <h1 className="text-[#d7c2b9] text-lg font-normal">
              {appointment.vendorName}
            </h1>
          </div>
          <br />
          <div className="text-[#d7c2b9] text-xs">
            <p className="mb-1 flex justify-between text-base font-light">
              <span>Appointment Date</span>
              <span>{appointment.booking_date}</span>
            </p>
            <p className="mb-1 flex justify-between text-base font-light">
              <span>Time of Appointment</span>
              <span>{appointment.booking_time}</span>
            </p>
            <p className="mb-1 flex justify-between text-base font-light">
              <span>Service</span>
              <span>{appointment.serviceName}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-between space-x-2 mt-2">
        <button
          onClick={handleCancel}
          className="text-red border border-[#52443d] px-4 py-2 rounded-md"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default PendingAppointments;
