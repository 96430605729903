import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";

const NotificationComponent = ({
  notifications,
  onClose,
  markAllAsRead,
  clearNotifications,
  markAsRead,
}) => {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <div
      id="modal-overlay"
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      onClick={(e) => {
        if (e.target.id === "modal-overlay") {
          onClose();
        }
      }}
    >
      <div className="bg-[#1a120e] text-white p-16 rounded-lg w-full max-w-4xl relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-300"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Notifications</h2>
          <div className="relative">
            <button
              onClick={() => setShowOptions(!showOptions)}
              className="text-gray-400 hover:text-gray-300"
            >
              <GiHamburgerMenu />
            </button>
            {showOptions && (
              <div className="absolute right-0 mt-2 w-48 bg-gray-700 rounded-md shadow-lg py-2 z-20">
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-200 hover:bg-gray-600"
                  onClick={markAllAsRead}
                >
                  Mark All as Read
                </a>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-200 hover:bg-gray-600"
                  onClick={clearNotifications}
                >
                  Clear
                </a>
              </div>
            )}
          </div>
        </div>
        <ul className="mt-4 space-y-4">
          {notifications.map((notification) => (
            <li
              key={notification.id}
              className={`p-4 rounded-lg flex items-center ${
                notification.read ? "bg-[#2e2b27]" : "bg-[#4b2e2b]"
              } cursor-pointer`}
              onClick={() => markAsRead(notification.id)}
            >
              {!notification.read && (
                <div className="w-3 h-3 bg-red rounded-full mr-4"></div>
              )}
              <div>
                <h3 className="font-bold">{notification.title}</h3>
                <p className="text-gray-400">{notification.message}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NotificationComponent;
